import React, { useEffect, useState, useContext } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext } from "../../stores/AppStore";
import { checkAuthToken } from '../../api/API';

import { Next, Prev } from '../NavButtons'
import { ErrorDisplay } from '../ErrorDisplay';

export const Step5 = observer(() => {

    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (appStore.checkToken){
            checkAuthToken(userStore.token,function(result:boolean){
                if (!result){
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
                else {
                    setDisplay(true);
                }
            })
        }
        else {
            setDisplay(true);
        }
    }, [])

    const nextPage = async () => {
        //process order
        appStore.errors = [];
        if (appStore.nameOnCard === ""){
            appStore.errors.push('Error: Please provide the name on the card')
        }
        if (appStore.creditCardNumber === ""){
            appStore.errors.push('Error: Please provide the credit card number')
        }
        if (appStore.expiryMonth === null || appStore.expiryYear === null){
            appStore.errors.push('Error: Please provide both month and year for the expiry')
        }
        if (appStore.securityCode === null){
            appStore.errors.push('Error: Please provide the security code on the back of the card')
        }
        if (appStore.errors.length===0){
            checkAuthToken(userStore.token, (result:boolean)=>{
                if (result){
                    appStore.loading = true;
                    appStore.submit = false;
                    appStore.step++;
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            })
        }
        else {
            window.scrollTo(0, 0)
        }
    }

    const date = new Date();
    let year = date.getFullYear();
    const yearOptions = Array<number|null>();
    yearOptions.push(null);
    for(let x=0;x<=20;x++){
        yearOptions.push(year+x);
    }
    const monthOptions = Array<number|null>();
    monthOptions.push(null);
    for(let x=1;x<=12;x++){
        monthOptions.push(x);
    }

    const creditCardNumberUpdate = (value:string)=>{
        appStore.creditCardNumber = value;
    }
    const expiryMonthUpdate = (value:number)=>{
        appStore.expiryMonth = value;
    }
    const expiryYearUpdate = (value:number)=>{
        appStore.expiryYear = value;
    }
    const nameOnCardUpdate = (value:string)=>{
        appStore.nameOnCard = value;
    }
    const securityCodeUpdate = (value:number)=>{
        appStore.securityCode = value;
    }

    if (display){
        return (
            <div className="step5">
                <h1>Part 5.) Payment</h1>
                <ErrorDisplay/>
                <p><strong>Fees for this Application:</strong></p>


                <p style={{fontSize:"18px"}}>Note: Additional Fees of $275 may be assessed to Renewal Applications for:</p>
                <ul>
                    <li>Remediation of incomplete renewal applications</li>
                    <li>Delay of an audit due to lack of preparedness</li>
                    <li>Post-audit remediation of non-compliance issues</li>
                </ul>
                <p style={{fontSize:"18px"}}>We will charge the card submitted according to the following fee schedule:</p>
                <div>
    <img src="https://isigmaonline.org/wp-content/uploads/2024/02/2024-ISIGMA-Prices-4-scaled.jpg" alt="2024 ISIGMA Prices" />
</div>
                <h2>Credit Card Authorization</h2>

                <label>Name on Card<br/>
                <input
                    type="text"
                    value={appStore.nameOnCard}
                    onChange={(event) => nameOnCardUpdate(event.target.value)} />
                </label>

                <label>Credit Card Number:<br/>
                <input
                    type="text"
                    value={appStore.creditCardNumber}
                    onChange={(event) => creditCardNumberUpdate(event.target.value)} />
                </label>

                <div className='ccfields'>
                    <p>Expiry</p>
                    <div className='expiry'>
                        <select onChange={(event)=>expiryMonthUpdate(parseInt(event.target.value,10))}>
                            {monthOptions.map((value,key)=>{return(<option key={key} selected={(value===appStore.expiryMonth)}>{value}</option>)})}
                        </select>
                        <select onChange={(event)=>expiryYearUpdate(parseInt(event.target.value,10))}>
                            {yearOptions.map((value,key)=>{return(<option key={key} selected={(value===appStore.expiryYear)}>{value}</option>)})}
                        </select>
                    </div>
                    <div className='security'>
                        <label>Security Code<br/>
                        <input
                            type="number"
                            value={(appStore.securityCode !== null ? appStore.securityCode : "")}
                            onChange={(event) => securityCodeUpdate(parseInt(event.target.value,10))} />
                        </label>
                    </div>
                </div>

                <div className="nextPrevSection">
                    <div>
                        <Prev onClick={()=>appStore.step--} buttonText="Back"/>
                    </div>
                    <div>
                        <Next onClick={nextPage} buttonText="Submit Application"/>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="step5">

            </div>
        )
    }
})