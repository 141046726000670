import React, {useContext} from 'react'
import {Page,Text,View,Document,StyleSheet,Font} from '@react-pdf/renderer'
import { AppStoreContext } from '../stores/AppStore';

Font.register({family:"Arial",fonts:[
    {src: `${process.env.PUBLIC_URL}/fonts/arial.ttf`},
    {src: `${process.env.PUBLIC_URL}/fonts/arialbd.ttf`, fontWeight: 700}
]})
Font.register({family:"Times New Roman",src:`${process.env.PUBLIC_URL}/fonts/times-new-roman.ttf`})
Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        fontFamily:'Arial',
        fontSize:14,
        padding:20,
        paddingTop:30,
        paddingBottom:30,
        justifyContent:"flex-start"
    },
    section: {
        margin: 12,
        lineHeight:1.3
    },
    spacing: {
        marginBottom: 5
    },
    tableRow: {
        flexDirection: 'row',
        width: "100%",
        justifyContent: 'space-between',
        marginBottom: 5
    },
    heading: {
        marginBottom: 10,
        fontFamily:'Times New Roman',
        fontSize: 22,
        color: "#005F61"
    },
    subheading: {
        fontSize: 16,
        fontWeight:700,
        marginTop: 10,
        marginBottom: 10
    },
    individualColumnHeading:{
        fontWeight:700,
        flexBasis: "11%",
        width: "11%",
    },
    individualColumnHeading25:{
        fontWeight:700,
        flexBasis: "26%",
        width: "26%",
    },
    individualColumn: {
        flexBasis: "11%",
        width: "11%",
    },
    individualColumn25: {
        flexBasis: "26%",
        width: "26%",
    },
    vehicleDetails:{
        flexBasis:"80%",
        width:"80%",
        flexWrap:"wrap",
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    vehicleField:{
        flexBasis: "50%",
        width:"50%"
    },
    bold: {
        fontFamily:'Arial',
        fontSize:14,
        lineHeight:1.3,
        fontWeight:700
    },
    standard: {
        fontFamily:'Arial',
        fontSize:14,
        lineHeight:1.3
    }
})

// Create Document Component
export const ApplicationPDF = () => {
    const appStore = useContext(AppStoreContext);

    const today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    const date = mm + '/' + dd + '/' + yyyy;

    function formatTimeString(timeString:string){
        const hr = parseInt(timeString.slice(0,2));
        const mn = timeString.slice(3,5);
        const AmOrPm = (hr >= 12 ? "PM" : "AM")
        const hour = (hr % 12) || 12
        return hour+":"+mn+" "+AmOrPm;
    }

    const firstDispatchDisplay = () => {
        if (appStore.firstDispatch === "Consistent Daily"){
            return <Text style={styles.standard}><Text style={styles.bold}>Time First Service Vehicle Dispatch:</Text> Consistent Daily @ {formatTimeString(appStore.firstDispatchTime)}</Text>
        }
        else if (appStore.firstDispatch === "Varies Daily"){
            let text = " "
            Object.keys(appStore.firstDispatchTimeWk).map((key)=>{
                text+=(appStore.firstDispatchTimeWk[key] ? key+": "+formatTimeString(appStore.firstDispatchTimeWk[key])+"; ":"")
            })
            return (
                <Text style={styles.standard}><Text style={styles.bold}>Time First Service Vehicle Dispatch:</Text> Varies Daily: {text}</Text>
            )
        }
        else {
            return <Text style={styles.standard}><Text style={styles.bold}>Time First Service Vehicle Dispatch:</Text> Not Applicable</Text>
        }
    }

    const storedAtOtherAddress = ()=>{
        let text = ""
        if (appStore.storedAtOtherAddress === 2){
            text = "No"
        }
        else if (appStore.storedAtOtherAddress === 1){
            text = "Yes (List Address): "+appStore.storedAtOtherAddressInput
        }
        else {
            text = "Not Applicable"
        }
        return <Text style={{...styles.standard,...styles.spacing}}><Text style={styles.bold}>Are Service Vehicles stored at location other than address above?:</Text> {text}</Text>
    }

    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.heading}>Annual Application for NAID AAA Certification for{'\n'}Physical Media Destruction</Text>
                    <Text><Text style={styles.bold}>Date:</Text> {date}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Part 1. Application Type</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Type:</Text> Renewal Application</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Applicant applying for Consulting Subsidy:</Text> {(appStore.consultingSubsidy === 1 ? "Yes":"No")}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Part 2. Contact Information </Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Name:</Text> {appStore.orgName}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Physical Address:</Text></Text>
                    <Text style={styles.spacing}>{appStore.orgPhysicalAddress}</Text>
                    <Text style={styles.spacing}>{appStore.orgCity}, {appStore.orgState}, {appStore.orgCountry} {appStore.orgMailCode}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Phone:</Text> {appStore.phone}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Fax:</Text> {appStore.fax}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Certification Contact:</Text> {appStore.certContact}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Email:</Text> {appStore.email}</Text>
                    {appStore.infoRequest !== "" &&
                        <Text style={styles.spacing}><Text style={styles.bold}>Information Update Request:</Text> {appStore.infoRequest}</Text>
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Part 3. Operational Information</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Number of Access Individuals:</Text> {appStore.numberAccessIndividuals}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Office Hours:</Text> {appStore.officeHours }</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Hours of Operation:</Text> {(appStore.operationHours !== "" ? appStore.operationHours : "Not Applicable")}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Service Vehicle Count:</Text> {appStore.vehicles.length }</Text>
                    <Text style={styles.spacing} render={firstDispatchDisplay} />
                    <Text style={styles.spacing} render={storedAtOtherAddress} />
                    <Text style={styles.spacing}><Text style={styles.bold}>Platforms: </Text>
                        {appStore.programs.facilityBased && <Text style={styles.standard}>Facility-based Destruction; </Text>}
                        {appStore.programs.mobileOnsite && <Text style={styles.standard}>Mobile / Onsite Destruction; </Text>}
                        {appStore.programs.transferProc && <Text style={styles.standard}>Transfer Processing Station; </Text>}
                        {appStore.programs.collectionOnly && <Text style={styles.standard}>Collection-only Facility; </Text>}
                    </Text>
                    {(appStore.programs.transferProc || appStore.programs.collectionOnly) &&
                        <Text style={styles.spacing}><Text style={styles.bold}>Address of Affiliated Destruction Facility:</Text> <Text style={styles.standard}>{appStore.affiliatedFacilityAddress}</Text></Text>
                    }
                    {(appStore.endorsements.paper || appStore.endorsements.hardDrives || appStore.endorsements.microMedia || appStore.endorsements.solidState || appStore.endorsements.nonPaper || appStore.endorsements.product) &&
                        <Text style={styles.spacing}><Text style={styles.bold}>Endorsements: </Text>
                            {appStore.endorsements.paper && <Text style={styles.standard}>Paper; </Text>}
                            {appStore.endorsements.microMedia && <Text style={styles.standard}>Micro Media; </Text>}
                            {appStore.endorsements.hardDrives && <Text style={styles.standard}>Hard Drives; </Text>}
                            {appStore.endorsements.nonPaper && <Text style={styles.standard}>Non-Paper Media (Optical Media); </Text>}
                            {appStore.endorsements.solidState && <Text style={styles.standard}>Solid-State Device; </Text>}
                            {appStore.endorsements.product && <Text style={styles.standard}>Product Destruction; </Text>}
                        </Text>
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Part 4. Required NAID AAA Specifications</Text>
                    <Text style={styles.subheading}>Section 1: Specs Applicable to all NAID AAA Certifications</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>1.3 Ongoing Substance Abuse Screening:</Text> Option #{appStore.substanceScreeningOption}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>1.4 Ongoing Access Individual Screening:</Text> Option #{appStore.accessIndividualScreeningOption}</Text>
                    {appStore.accessIndividualScreeningOption === 3 &&
                        <Text style={styles.spacing}><Text style={styles.bold}>Year of most recent search:</Text> {appStore.accessIndividualScreeningYear}</Text>
                    }
                    <Text style={styles.spacing}><Text style={styles.bold}>1.12 Ongoing Access Individual Screening:</Text> Option #{appStore.accessIndividualTrainingOption}</Text>
                    {appStore.programs.transferProc && appStore.transferOfCustodyOption !== 0 &&
                        <Text style={styles.spacing}><Text style={styles.bold}>1.20 Transfer of Custody:</Text> Option #{appStore.transferOfCustodyOption}{(appStore.transferOfCustodyOption === 3 ? ": "+appStore.transferOfCustodyOther : "")}</Text>
                    }
                    <Text style={styles.spacing}><Text style={styles.bold}>1.27 DPO Officer:</Text> {appStore.designatedDPO} &lt;{appStore.designatedDPOemail}&gt;</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>1.28 ICCO Officer:</Text> {appStore.designatedICCO} &lt;{appStore.designatedICCOemail}&gt;</Text>

                    {(appStore.endorsements.paper || appStore.endorsements.hardDrives || appStore.endorsements.microMedia || appStore.endorsements.solidState || appStore.endorsements.nonPaper || appStore.endorsements.product) &&
                        <div>
                            <Text style={styles.subheading}>Section 4: Additional Specs Applicable to NAID AAA Certification Media Destruction</Text>
                            {appStore.endorsements.paper &&
                                <div style={{marginBottom:10}}>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.1 Paper / Printed Media Physical Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.paperFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.paperMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Paper Media Destruction / Methodology: </Text>
                                        {appStore.paperContinuousShred && <Text style={styles.standard}>Continuous Shred; </Text>}
                                        {appStore.paperCrossCut && <Text style={styles.standard}>Cross Cut or Pierce &amp; Tear; </Text>}
                                        {appStore.paperPulDisHamm && <Text style={styles.standard}>Pulverizer, Disintegrator or Hammermill; </Text>}
                                    </Text>
                                    <Text style={styles.spacing}>
                                        {appStore.paperUnspecified && <Text style={styles.standard}>Unspecified Equipment: {appStore.paperUnspecifiedField}</Text>}
                                    </Text>
                                    <Text style={[styles.bold,styles.spacing]}>Primary Equipment:</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Mobile / Onsite of Facility Equipment:</Text> {appStore.paperOnsiteOrFacility}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Manufacturer:</Text> {appStore.paperManufacturer}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Model:</Text> {appStore.paperModel}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Serial #:</Text> {appStore.paperSerial}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Horsepower:</Text> {appStore.paperHorsepower}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Additional Equipment Information Uploaded:</Text> {(appStore.paperAdditionalEquipment ? "Yes" : "No")}</Text>
                                </div>
                            }
                            {appStore.endorsements.microMedia &&
                                <div style={{marginBottom:10}}>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.2 Micro Media Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.microFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.microMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                    <Text style={[styles.bold,styles.spacing]}>Primary Equipment:</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Mobile / Onsite of Facility Equipment:</Text> {appStore.microOnsiteOrFacility}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Manufacturer:</Text> {appStore.microManufacturer}</Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Model:</Text> {appStore.microModel}</Text>
                                </div>
                            }
                            {appStore.endorsements.hardDrives &&
                                <div style={{marginBottom:10}}>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.3 Hard Drive Physical Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.hardDriveFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.hardDriveMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Hard Drive Physical Destruction Method:</Text> {appStore.hardDriveMethod}</Text>
                                </div>
                            }
                            {appStore.endorsements.solidState &&
                                <div style={{marginBottom:10}}>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.4 Solid-State Device Physical Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.solidStateFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.solidStateMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Solid-State Device Physical Destruction Method:</Text> {appStore.solidStateMethod}</Text>
                                </div>
                            }
                            {appStore.endorsements.nonPaper &&
                                <div style={{marginBottom:10}}>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.5 Non-Paper Physical Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.nonPaperFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.nonPaperMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Types of Non-Paper Media physically destroyed: </Text>
                                        {appStore.nonPaperOpticalMedia && <Text style={styles.standard}>Optical Media; </Text>}
                                        {appStore.nonPaperMagneticTape && <Text style={styles.standard}>Magnetic Tape Media; </Text>}
                                        {appStore.nonPaperOther && <Text style={styles.standard}>Other: {appStore.nonPaperOtherField}</Text>}
                                    </Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Non-Paper Media Physical Destruction Method:</Text> {appStore.nonPaperMethod}</Text>
                                </div>
                            }
                            {appStore.endorsements.product &&
                                <div>
                                    <Text style={styles.spacing}><Text style={styles.bold}>4.18 Product Destruction Endorsement</Text></Text>
                                    <Text style={styles.spacing}><Text style={styles.bold}>Service Platform: </Text>
                                        {appStore.productFacilityBased && <Text style={styles.standard}>Facility-Based Destruction; </Text>}
                                        {appStore.productMobileOnsite && <Text style={styles.standard}>Mobile / Onsite; </Text>}
                                    </Text>
                                </div>
                            }
                        </div>
                    }
                </View>
            </Page>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.heading}>Payment Details</Text>
                    <Text style={styles.subheading}>Application Fees</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Applying For:</Text> {appStore.feeNames}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Fees:</Text> ${appStore.totalFees.toFixed(2)} USD</Text>
                    <Text style={styles.subheading}>Payment Information</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Name on Card</Text> {appStore.nameOnCard}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Credit Card Number</Text> {appStore.creditCardNumber}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Expiry</Text> {appStore.expiryMonth}/{appStore.expiryYear}</Text>
                    <Text style={styles.spacing}><Text style={styles.bold}>Security Code</Text> {appStore.securityCode}</Text>
                </View>
            </Page>
            <Page size="LETTER" style={styles.page} orientation="landscape">
                <View style={styles.section}>
                    <Text style={styles.heading}>Access and Non-Access Individuals List</Text>
                </View>
                <View style={styles.section}>
                    <div style={styles.tableRow}>
                        <Text style={styles.individualColumnHeading25}>Name</Text>
                        <Text style={styles.individualColumnHeading25}>Date of Hire</Text>
                        <Text style={styles.individualColumnHeading}>Access?</Text>
                        <Text style={styles.individualColumnHeading25}>Title / Position</Text>
                        <Text style={styles.individualColumnHeading}>Citizen?</Text>
                    </div>
                    {appStore.individuals.map((individual) => {
                        const dateNum = new Date(individual.date);
                        let dd = String(dateNum.getDate()).padStart(2, '0');
                        let mm = String(dateNum.getMonth() + 1).padStart(2, '0');
                        let yyyy = dateNum.getFullYear();
                        const date = mm + '/' + dd + '/' + yyyy;
                        return (
                            <div style={styles.tableRow}>
                                <Text style={styles.individualColumn25}>{individual.name}</Text>
                                <Text style={styles.individualColumn25}>{date}</Text>
                                <Text style={styles.individualColumn}>{(individual.access ? "Yes" : "No")}</Text>
                                <Text style={styles.individualColumn25}>{individual.title}</Text>
                                <Text style={styles.individualColumn}>{(individual.citizen ? "Yes" : "No")}</Text>
                            </div>
                        )
                        }
                    )
                    }
                </View>
            </Page>
            {appStore.vehicles.length > 0 &&
                <Page size="LETTER" style={styles.page} orientation="landscape">
                    <View style={styles.section}>
                        <Text style={styles.heading}>Service Vehicle List</Text>
                    </View>
                    <View style={styles.section}>
                        <div style={styles.tableRow}>
                            <Text style={styles.bold}>Type</Text>
                            <Text style={[styles.vehicleDetails,styles.bold]}>Details</Text>
                        </div>
                        {appStore.vehicles.map((vehicle) =>
                            <div style={styles.tableRow}>
                                <div>
                                    <Text>{vehicle.type}</Text>
                                </div>
                                <div style={styles.vehicleDetails}>
                                    <Text style={styles.vehicleField}>VIN: {vehicle.vin}</Text>
                                    <Text style={styles.vehicleField}>Make/Model: {vehicle.make}</Text>
                                    <Text style={styles.vehicleField}>Plate: {vehicle.plate}</Text>
                                    <Text style={styles.vehicleField}>Prov/State: {vehicle.state}</Text>
                                    <Text style={styles.vehicleField}>Overnight Address: {vehicle.overnight}</Text>
                                    <Text style={styles.vehicleField}>Available for Audit: {(vehicle.audit ? "Yes" : "No")}</Text>
                                </div>
                            </div>
                        )
                        }
                    </View>
                </Page>
            }
        </Document>
    )
};