import React, {useContext} from 'react';
import {observer} from "mobx-react";
import {AppStoreContext} from "../stores/AppStore";

export const ErrorDisplay = observer(() => {
    const appStore = useContext(AppStoreContext);
    interface Props{
        value:string
        index:number
    }
    const Error = observer((props:Props)=>{
        const dismissError = () => {
            appStore.errors.splice(props.index,1);
        }
        return (
            <div className='status error'>
                <p>{props.value}</p>
                <button onClick={()=>dismissError()}>Close</button>
            </div>
        )
    })
    return (
        <div className='errorsWrapper'>
            {appStore.errors.length > 0 &&
                appStore.errors.map((object,key)=><Error key={key} index={key} value={object} />)
            }
        </div>
    )
})