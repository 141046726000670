import React, { useEffect, useContext, useState } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext } from "../../stores/AppStore";
import { ErrorDisplay } from '../ErrorDisplay'
import { checkAuthToken, updateOrganization } from '../../api/API';

import { Next } from '../NavButtons'

export const Step1 = observer(() => {

    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (appStore.checkToken){
            checkAuthToken(userStore.token,function(result:boolean){
                if (!result){
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
                else {
                    setDisplay(true);
                }
            })
        }
        else {
            setDisplay(true);
        }
    }, [])

    function nextPage(){
        appStore.loading = true;
        appStore.errors=[];
        if (appStore.offerEEDS === 0){
            appStore.errors.push("Error: You must specify if you offer electronic erasure / degaussing services.")
        }
        if (appStore.consultingSubsidy === 0){
            appStore.errors.push("Error: You must specify if you will be applying for the Consulting Subsidy.")
        }
        if (appStore.errors.length === 0){
            checkAuthToken(userStore.token, (result:boolean)=>{
                if (result){
                    const fields = {
                        "options": [{
                            "Name": "ConsultingSubsidy__c",
                            "Value": (appStore.consultingSubsidy === 1 ? "True" : "False")
                        }]
                    }
                    updateOrganization( userStore.token, appStore.orgID, fields, (response:any)=>{
                        appStore.loading=false;
                        appStore.checkToken=false;
                        appStore.step++;
                    })
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            });
        }
        else {
            window.scrollTo(0, 0)
        }
    }
    const offerEEDSUpdate = (value:number) => {
        appStore.offerEEDS = value;
    }
    const consultingSubsidyUpdate = (value:number) => {
        appStore.consultingSubsidy = value;
    }

    if (display){
        return (
            <div className="step1">
                <h1>Part 1.) Application Type</h1>

                <p><strong>Disclaimer</strong></p>

                <p>Information entered can only be saved by proceeding via “Next” button at the bottom of each page. Logging off or timing out mid-page will cause a loss of entered information on only that (unsaved) page. To return to saved information, log back in and check “No” where asked if you offer Electronic Erasure/Degaussing Services, to proceed to application.</p>

                <p><strong>The following application serves those who only provide Physical Destruction services.</strong></p>
                <ErrorDisplay />
                <p>Application Type: Renewal Application</p>

                <p>Do you offer Electronic Erasure / Degaussing Services?</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => offerEEDSUpdate(1)}>
                        <span className={appStore.offerEEDS === 1 ? "checked" : ""}></span>
                    Yes
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => offerEEDSUpdate(2)}>
                        <span className={appStore.offerEEDS === 2 ? "checked" : ""}></span>
                    No
                    </label>
                </p>
                <p><strong>Consulting Subsidy:</strong> <em>A $400 post-audit rebate is available after to partially reimburse for the expense of an i-SIGMA-approved consultant to assist with the Initial application process.</em></p>
                <p>Will the Applicant be applying for the Consulting Subsidy post audit?</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => consultingSubsidyUpdate(1)}>
                        <span className={appStore.consultingSubsidy === 1 ? "checked" : ""}></span>
                    Yes
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => consultingSubsidyUpdate(2)}>
                        <span className={appStore.consultingSubsidy === 2 ? "checked" : ""}></span>
                    No
                    </label>
                </p>
                {appStore.offerEEDS === 2 &&
                <div className="nextPrevSection">
                    <div>
                    </div>
                    <div>
                        <Next onClick={nextPage} buttonText="Next"/>
                    </div>
                </div>
                }
                {appStore.offerEEDS === 1 &&
                    <p className='errorText'><strong>Unfortunately, you may not proceeed with this application as it only serves Physical Destruction applicants.</strong> To continue, please download the <a target='_blank' rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/downloads/Certification Application Packet - NAID AAA for Electronic Media Erasure 0623J.pdf`}>PDF Electronic Erasure / Degaussing Application</a> and submit to certification@isigmaonline.org</p>
                }
            </div>
        )
    }
    else {
        return (
            <div className="step1">

            </div>
        )
    }
})