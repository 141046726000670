import { membersuiteURL, tenantId } from "./APICredentials";
import { Vehicle, Individual } from "../stores/AppStore";

export const checkAuthToken = async (token:string,callback:Function)=>{
    if (!token){
        console.log('No Token Set')
        callback(false);
    }
    else {
        //Check if our current token works
        const whoIsHeaders = new Headers();
        whoIsHeaders.append("Authorization", token);

        const whoIsRequestOptions = {
            method: 'GET',
            headers: whoIsHeaders
        };

        let status = 0
        fetch(membersuiteURL+"/platform/v2/whoami", whoIsRequestOptions)
        .then(response => {
            status = response.status;
            return response.json()
        })
        .then((result)=>{
            if (status === 401){
                console.log('Generate New Token')
                callback(false);
            }
            else {
                console.log('Current Token O.K.')
                callback(true,result);
            }
        })
        .catch(error => {
            console.log(error)
            alert('E101: Could Not Connect. Try again later.')
            window.location.replace(`${process.env.PUBLIC_URL}`);
        });
    }
}

export const updateOrganization = async (token:string,id:string,fields:Object,callback:Function)=>{
    const updateOrganizationHeaders = new Headers();
    updateOrganizationHeaders.append("Authorization", token);
    updateOrganizationHeaders.append("Content-Type", 'application/json');

    const updateOrganizationOptions = {
        method: 'PUT',
        headers: updateOrganizationHeaders,
        body: JSON.stringify(fields)
    };
    fetch(membersuiteURL+'/crm/v1/organizations/'+id,updateOrganizationOptions)
    .then(response=>response.text())
    .then((response)=>{
        callback(response);
    }).catch(error => {
        console.log(error)
        alert('E102: Could Not Update Record. Try again later.')
        window.location.replace(`${process.env.PUBLIC_URL}`);
    });
}


export const saveAndUpdateIndividuals = async (token:string,orgid:string,individuals:Array<Individual>,deletedIndividuals:Array<Individual>,numberAccessIndividuals:number,callback:Function)=>{
    const individualsHeaders = new Headers();
    individualsHeaders.append("Authorization", token);
    individualsHeaders.append("Content-Type", 'application/json');

    let fetches = [];

    if (deletedIndividuals.length > 0){
        for(const entry of Object.entries(deletedIndividuals)){
            const individual = entry[1]
            const deleteIndividualOptions = {
                method: 'DELETE',
                headers: individualsHeaders
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/delete/'+individual.id,deleteIndividualOptions)
            .then(response=>response.text()))
        }
    }

    for (const entry of Object.entries(individuals)){
        const individual = entry[1];
        if (individual.id.startsWith('temp-')){
            //Add New Individual
            const postfields = {
                owner: orgid,
                customObject: "3ff355cb-0018-c941-f233-0b44098ea909",  //AccessInd__c
                EmpName__c: individual.name,
                DateofHire__c: individual.date,
                Access__c: (individual.access === 1 ? "True" : "False"),
                Title__c: individual.title,
                Citizen__c: (individual.citizen === 1 ? "True" : "False")
            }
            const addIndividualOptions = {
                method: 'POST',
                headers: individualsHeaders,
                body: JSON.stringify(postfields)
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/',addIndividualOptions)
            .then(response=>response.text()))
        }
        else {
            //Update Individual
            const updatefields = {
                id: individual.id,
                customObject: "3ff355cb-0018-c941-f233-0b44098ea909",  //AccessInd__c
                EmpName__c: individual.name,
                DateofHire__c: individual.date,
                Access__c: (individual.access === 1 ? "True" : "False"),
                Title__c: individual.title,
                Citizen__c: (individual.citizen === 1 ? "True" : "False")
            }

            const updateIndividualOptions = {
                method: 'PUT',
                headers: individualsHeaders,
                body: JSON.stringify(updatefields)
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/'+individual.id,updateIndividualOptions)
            .then(response=>response.text()))
        }
    }

    const orgfields = {
        "options": [{
            "Name": "NoAccEmp__c",
            "Value": numberAccessIndividuals
        }]
    }

    const updateOrganizationHeaders = new Headers();
    updateOrganizationHeaders.append("Authorization", token);
    updateOrganizationHeaders.append("Content-Type", 'application/json');

    const updateOrganizationOptions = {
        method: 'PUT',
        headers: updateOrganizationHeaders,
        body: JSON.stringify(orgfields)
    };
    fetches.push(fetch(membersuiteURL+'/crm/v1/organizations/'+orgid,updateOrganizationOptions)
    .then(response=>response.text()))

    Promise.all(fetches).then((res)=>{
        //Get Updated Employee Information
        let empurl = new URL(membersuiteURL+"/platform/v2/customobjects/"+tenantId)
        const empQuery = "SELECT EmpName__c, DateofHire__c, Access__c, Title__c, Citizen__c "
        + "FROM AccessInd__c WHERE Owner = '"+orgid+"'"

        empurl.searchParams.append("page", "1");
        empurl.searchParams.append("pageSize", "100");
        empurl.searchParams.append("msql", empQuery);

        const empHeaders = new Headers();
        empHeaders.append("Authorization", token);

        const employeeRequestOptions = {
            method: 'GET',
            headers: empHeaders
        };
        fetch(empurl.toString(),employeeRequestOptions)
        .then(response=>response.json())
        .then((get)=>{
            callback(get)
        }).catch(function(err) {
            console.log(err.message);
        });
    }).catch(error => {
        console.log(error)
        alert('E103: Could Not Update Record. Try again later.')
        window.location.replace(`${process.env.PUBLIC_URL}`);
    });
}

export const saveAndUpdateVehicles = async (token:string,orgid:string,vehicles:Array<Vehicle>,deletedVehicles:Array<Vehicle>,callback:Function)=>{
    const vehiclesHeaders = new Headers();
    vehiclesHeaders.append("Authorization", token);
    vehiclesHeaders.append("Content-Type", 'application/json');

    let fetches = [];

    if (deletedVehicles.length > 0){
        for(const entry of Object.entries(deletedVehicles)){
            const vehicle = entry[1]
            const deleteVehicleOptions = {
                method: 'DELETE',
                headers: vehiclesHeaders
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/delete/'+vehicle.id,deleteVehicleOptions)
            .then(response=>response.text()))
        }
    }

    for (const entry of Object.entries(vehicles)){
        const vehicle = entry[1];
        if (vehicle.id.startsWith('temp-')){
            //Add  New Vehicle
            const fields = {
                owner: orgid,
                customObject: "3ff355cb-0018-ca3b-a1e8-0b44098f4a7c",  //SvcVehList__c
                TypeVeh__c: vehicle.type,
                VinNo__c: vehicle.vin,
                MakeAndModel__c: vehicle.make,
                LicPlateNo__c: vehicle.plate,
                StateIssued__c: vehicle.state,
                OvernightAddress__c: vehicle.overnight,
                AvailableForAudit__c: (vehicle.audit === 1 ? "True" : "False")
            }
            const addVehicleOptions = {
                method: 'POST',
                headers: vehiclesHeaders,
                body: JSON.stringify(fields)
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/',addVehicleOptions)
            .then(response=>response.text()))
        }
        else {
            //Update Vehicle
            const fields = {
                id: vehicle.id,
                customObject: "3ff355cb-0018-ca3b-a1e8-0b44098f4a7c",  //SvcVehList__c
                TypeVeh__c: vehicle.type,
                VinNo__c: vehicle.vin,
                MakeAndModel__c: vehicle.make,
                LicPlateNo__c: vehicle.plate,
                StateIssued__c: vehicle.state,
                OvernightAddress__c: vehicle.overnight,
                AvailableForAudit__c: (vehicle.audit === 1 ? "True" : "False")
            }

            const updateVehicleOptions = {
                method: 'PUT',
                headers: vehiclesHeaders,
                body: JSON.stringify(fields)
            };
            fetches.push(fetch(membersuiteURL+'/platform/v2/customobjectinstances/'+vehicle.id,updateVehicleOptions)
            .then(response=>response.text()))
        }
    }

    const orgfields = {
        "options": [{
            "Name": "NoVehicles__c",
            "Value": vehicles.length
        }]
    }

    const updateOrganizationHeaders = new Headers();
    updateOrganizationHeaders.append("Authorization", token);
    updateOrganizationHeaders.append("Content-Type", 'application/json');

    const updateOrganizationOptions = {
        method: 'PUT',
        headers: updateOrganizationHeaders,
        body: JSON.stringify(orgfields)
    };
    fetches.push(fetch(membersuiteURL+'/crm/v1/organizations/'+orgid,updateOrganizationOptions)
    .then(response=>response.text()))

    Promise.all(fetches).then((res)=>{
        //Get Updated Vehicle Information
        let vehurl = new URL(membersuiteURL+"/platform/v2/customobjects/"+tenantId)
        const vehQuery = "SELECT TypeVeh__c, VinNo__c, MakeAndModel__c, LicPlateNo__c, StateIssued__c, OvernightAddress__c, AvailableForAudit__c "
        + "FROM SvcVehList__c WHERE Owner = '"+orgid+"'"

        vehurl.searchParams.append("page", "1");
        vehurl.searchParams.append("pageSize", "100");
        vehurl.searchParams.append("msql", vehQuery);

        const vehHeaders = new Headers();
        vehHeaders.append("Authorization", token);

        const vehicleRequestOptions = {
            method: 'GET',
            headers: vehHeaders
        };
        fetch(vehurl.toString(),vehicleRequestOptions)
        .then(response=>response.json())
        .then((get)=>{
            callback(get)
        })
    }).catch(error => {
        console.log(error)
        alert('E103: Could Not Update Record. Try again later.')
        window.location.replace(`${process.env.PUBLIC_URL}`);
    });
}