import React, { useContext, useEffect } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext } from "../../stores/AppStore";

import { Next, Prev } from '../NavButtons'

import { scriptsURL } from '../../api/APICredentials';
import {usePDF} from '@react-pdf/renderer';
import { ApplicationPDF } from '../../pdf/PDFRender';
import { ErrorDisplay } from '../ErrorDisplay';

export const Step6 = observer(() => {

    const appStore = useContext(AppStoreContext);

    const [PDFInstance, updatePDFInstance] = usePDF({ document: <ApplicationPDF/> });

    const GeneratePDF = observer(() => {

        if (PDFInstance.blob !== null && appStore.submit !== true){
            appStore.submit = true;
            const formData = new FormData();
            formData.append('pdf',PDFInstance.blob);
            if (appStore.paperAdditionalFileName !== ''){
                formData.append('attach',appStore.paperAdditionalFile);
            }
            formData.append('orgID',appStore.orgID)
            formData.append('orgName',appStore.orgName)
            formData.append('orgAddress',appStore.orgPhysicalAddress)
            formData.append('orgCity',appStore.orgCity)
            formData.append('orgState',appStore.orgState)
            formData.append('orgCountry',appStore.orgCountry)
            formData.append('orgPostal',appStore.orgMailCode)

            const emailRequestOptions = {
                method: 'POST',
                body: formData,
                contentType: false,
                processData: false
            };

            //send email
            fetch(scriptsURL+'/sendEmail.php', emailRequestOptions)
            .then(response => response.json())
            .then((result)=>{
                if (result.success){
                    appStore.loading = false;
                }
                else {
                    if (result.message){
                        appStore.errors.push(result.message)
                    }
                    else {
                        appStore.errors.push("Application failed to send.")
                    }
                }
            })
            .catch((error)=>{
                console.log(error)
                appStore.errors.push("Application failed to send.")
            });

        }
        return (
            <p style={{textAlign:"center"}}>Generating application...</p>
        )
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const prevStep = ()=>{
        appStore.checkToken=true;
        appStore.step--
    }
    const loadDashboard = (event:any) => {
        event.preventDefault();
        appStore.loading = true;
        appStore.page = "Dashboard";
    }

    if (PDFInstance.error) {
        return (
            <div className="step6">
                <h1>APPLICATION FAILED TO SUBMIT</h1>

                <p>{PDFInstance.error}</p>

                <div className="nextPrevSection">
                    <div>
                    </div>
                    <div>
                        <Prev onClick={()=>{prevStep()}} buttonText="Try Again"/>
                    </div>
                </div>
            </div>
        )
    }
    else if (appStore.errors.length > 0){
        return (
            <div className="step6">
                <h1>APPLICATION FAILED TO SUBMIT</h1>

                <ErrorDisplay />

                <div className="nextPrevSection">
                    <div>
                    </div>
                    <div>
                        <Prev onClick={()=>{prevStep()}} buttonText="Try Again"/>
                    </div>
                </div>
            </div>
        )
    }
    else {
        if (!appStore.submit){
            return (
                <div className="step6">
                    <img src={`${process.env.PUBLIC_URL}/images/loader-lg.gif`} alt="Loading" className='large-loader' />
                    <GeneratePDF/>
                </div>
            )
        }
        else if (appStore.loading){
            return (
                <div className="step6">
                    <img src={`${process.env.PUBLIC_URL}/images/loader-lg.gif`} alt="Loading" className='large-loader' />
                    <p style={{textAlign:"center"}}>Submitting application...</p>
                </div>
            )
        }
        else {
            return (
                <div className="step6">
                    <h1>APPLICATION SUBMITTED</h1>

                    <p>Your application has been received and will soon be reviewed.</p>
                    <p>i-SIGMA Certification staff will be in touch if additional information is needed.</p>
                    <p>Payment will be processed upon initial application review, and a receipt sent directly to your organization's Billing Contact at that time.</p>

                    <div className="nextPrevSection">
                        <div>
                        </div>
                        <div>
                            <Next onClick={(e:any)=>{loadDashboard(e)}} buttonText="Return to Profile"/>
                        </div>
                    </div>
                </div>
            )
        }
    }
})