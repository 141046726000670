import React, { useEffect, useState, useContext} from 'react';
import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext } from "../../stores/AppStore";
import { checkAuthToken, updateOrganization } from '../../api/API';
import { membersuiteURL, tenantId } from '../../api/APICredentials';

import { Next, Prev } from '../NavButtons'
import { ErrorDisplay } from '../ErrorDisplay';
import { FileUpload } from './FileUpload';

export const Step4 = observer(() => {

    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const Section1 = observer(() => {
        const [display, setDisplay] = useState(false);

        useEffect(() => {
            window.scrollTo(0, 0)
            if (appStore.checkToken){
                checkAuthToken(userStore.token,function(result:boolean){
                    if (!result){
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                    else {
                        setDisplay(true);
                    }
                })
            }
            else {
                setDisplay(true);
            }
        }, [])

        const prevPage = () => {
            appStore.errors=[]
            appStore.checkToken=true;
            appStore.step--
        }
        const nextSection = () => {
            appStore.errors=[]
            if (appStore.substanceScreeningOption === 0){
                appStore.errors.push('Error: Select an option under 1.3')
            }
            if (appStore.accessIndividualScreeningOption === 0){
                appStore.errors.push('Error: Select an option under 1.4')
            }
            else if (appStore.accessIndividualScreeningOption === 3){
                if (appStore.accessIndividualScreeningYear === ""){
                    appStore.errors.push('Error: Please specify the year of most recent search under 1.4')
                }
            }
            if (appStore.accessIndividualTrainingOption === 0){
                appStore.errors.push('Error: Select an option under 1.12')
            }
            if (appStore.transferOfCustodyOption === 3){
                if (appStore.transferOfCustodyOther === ""){
                    appStore.errors.push('Error: Please specify the subcontractors under 1.20')
                }
            }
            function checkEmail(email:string){
                const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (email.match(validRegex)){
                    return true;
                }
                else {
                    return false;
                }
            }
            if (appStore.designatedDPO === "" || checkEmail(appStore.designatedDPOemail) === false){
                appStore.errors.push('Error: Please specify the designated DPO Officer and a valid email address under 1.27')
            }
            if (appStore.designatedICCO === "" || checkEmail(appStore.designatedICCOemail) === false){
                appStore.errors.push('Error: Please specify the designated ICCO Officer and a valid email address under 1.28')
            }

            if (appStore.errors.length === 0){
                appStore.loading=true;
                checkAuthToken(userStore.token, (result:boolean)=>{
                    if (result){
                        const fields = {
                            "options": [{
                                "Name": "F_1_3SubAbuseScreening__c",
                                "Value": "Option " + appStore.substanceScreeningOption
                            },
                            {
                                "Name": "F_1_4AccIndividualScreening__c",
                                "Value": "Option " + appStore.accessIndividualScreeningOption
                            },
                            {
                                "Name": "F_1_4MostRecentSearch__c",
                                "Value": appStore.accessIndividualScreeningYear
                            },
                            {
                                "Name": "F_1_1AETP__c",
                                "Value": "Option " + appStore.accessIndividualTrainingOption
                            },
                            {
                                "Name": "F_1_20TransferofCustody__c",
                                "Value": (appStore.transferOfCustodyOption === 0 ? "" : "Option " + appStore.transferOfCustodyOption)
                            },
                            {
                                "Name": "F_1_20TransofCusOTHER__c",
                                "Value": appStore.transferOfCustodyOther
                            },
                            {
                                "Name": "F_1_27DPOOfficer__c",
                                "Value": appStore.designatedDPO
                            },
                            {
                                "Name": "F_1_27DPOemail__c",
                                "Value": appStore.designatedDPOemail
                            },
                            {
                                "Name": "F_1_28ICCOOfficer__c",
                                "Value": appStore.designatedICCO
                            },
                            {
                                "Name": "F_1_28iccoemailadd__c",
                                "Value": appStore.designatedICCOemail
                            }
                            ]
                        }
                        updateOrganization( userStore.token, appStore.orgID, fields, (response:any)=>{
                            appStore.checkToken=false;
                            if (!appStore.programs.facilityBased){
                                appStore.substep="section4";
                            }
                            else {
                                appStore.substep="section2";
                            }
                            appStore.loading=false;
                        })
                    }
                    else {
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                });
            }
            else {
                window.scrollTo(0,0)
            }
        }

        const substanceScreeningOptionUpdate = (value:number) => {
            appStore.substanceScreeningOption = value;
        }
        const accessIndividualScreeningOptionUpdate = (value:number) => {
            appStore.accessIndividualScreeningOption = value;
        }
        const accessIndividualScreeningYearUpdate = (value:string)=>{
            appStore.accessIndividualScreeningYear = value;
        }
        const accessIndividualTrainingOptionUpdate = (value:number) => {
            appStore.accessIndividualTrainingOption = value;
        }
        const transferOfCustodyOptionUpdate = (value:number) => {
            if (appStore.transferOfCustodyOption === value){
                appStore.transferOfCustodyOption = 0;
            }
            else {
                appStore.transferOfCustodyOption = value;
            }
        }
        const transferOfCustodyOtherUpdate = (value:string)=>{
            appStore.transferOfCustodyOther = value;
        }
        const designatedDPOUpdate = (value:string)=>{
            appStore.designatedDPO = value;
        }
        const designatedDPOemailUpdate = (value:string)=>{
            appStore.designatedDPOemail = value;
        }
        const designatedICCOUpdate = (value:string)=>{
            appStore.designatedICCO = value;
        }
        const designatedICCOemailUpdate = (value:string)=>{
            appStore.designatedICCOemail = value;
        }

        if (display){
            return (
                <div className='section1'>
                    <h2>SECTION 1: SPECIFICATIONS APPLICABLE TO ALL NAID AAA CERTIFICATIONS</h2>
                    <h3>1.1 CITIZENSHIP / WORK ELIGIBILITY REQUIREMENT</h3>
                    <h3>1.2 INITIAL ACCESS INDIVIDUAL SCREENING REQUIREMENT</h3>
                    <h3>1.3 ONGOING SUBSTANCE ABUSE SCREENING</h3>
                    <p>Select one:</p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => substanceScreeningOptionUpdate(1)}>
                            <span className={appStore.substanceScreeningOption === 1 ? "checked" : ""}></span>
                            <span><strong>Option #1</strong>: Upon hire and thereafter on a random basis, 50% of Access Individuals are drug screened annually.</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => substanceScreeningOptionUpdate(2)}>
                            <span className={appStore.substanceScreeningOption === 2 ? "checked" : ""}></span>
                            <span><strong>Option #2</strong>: Management has been trained in a “Substance Abuse Recognition Awareness Program” pre-approved by i-SIGMA. (Submit i-SIGMA “Substance Abuse Recognition Training Program” (SARP) form with application for approval along with an outline of the training, or if approval has already been obtained submit the approved copy of the form for review.)</span>
                        </label>
                    </p>
                    <h3>1.4 ONGOING ACCESS INDIVIDUAL SCREENING</h3>
                    <p>Select one of the 3 options to be used for ongoing criminal record searches on Access Individuals:</p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualScreeningOptionUpdate(1)}>
                            <span className={appStore.accessIndividualScreeningOption === 1 ? "checked" : ""}></span>
                            <span><strong>Option #1</strong>: One-third of Access Individuals have been randomly selected and criminal record searches conducted annually.</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualScreeningOptionUpdate(2)}>
                            <span className={appStore.accessIndividualScreeningOption === 2 ? "checked" : ""}></span>
                            <span><strong>Option #2</strong>: One-third of all Access Individuals are screened the first year, a different 1/3 are screened the following year, and the remaining 1/3 are screened in the third year.</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualScreeningOptionUpdate(3)}>
                            <span className={appStore.accessIndividualScreeningOption === 3 ? "checked" : ""}></span>
                            <span><strong>Option #3</strong>: All Access Individuals have criminal record searches conducted every three years.</span>
                        </label>
                    </p>
                    {appStore.accessIndividualScreeningOption === 3 &&
                        <label>Year of most recent search:<br/>
                        <input
                            type="text"
                            value={appStore.accessIndividualScreeningYear}
                            onChange={(event) => accessIndividualScreeningYearUpdate(event.target.value)} />
                        </label>
                    }
                    <h3>1.5 DRIVER QUALIFICATIONS</h3>
                    <h3>1.6 WRITTEN POLICIES AND PROCEDURES AND ACCESS INDIVIDUAL AWARENESS ATTESTATION</h3>
                    <h3>1.7 WRITTEN DRIVER / FIELD OPERATIONS POLICIES AND PROCEDURES</h3>
                    <h3>1.8 MANAGEMENT BREACH NOTIFICATION ACCOUNTABILITY</h3>
                    <h3>1.9 ACCESS INDIVIDUAL BREACH NOTIFICATION POLICY / TRAINING</h3>
                    <h3>1.10 INCIDENT RESPONSE PLAN</h3>
                    <h3>1.11 UNANNOUNCED AUDIT PROCEDURES</h3>
                    <h3>1.12 ACCESS INDIVIDUAL TRAINING</h3>
                    <p>Select the Option below used to train Access Individuals annually to comply with the applicable certification requirements.</p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualTrainingOptionUpdate(1)}>
                            <span className={appStore.accessIndividualTrainingOption === 1 ? "checked" : ""}></span>
                            <span><strong>Option #1</strong>: All Access Individuals have taken and passed the i-SIGMA Access Individual Training Program (AETP). (Submit “Access Employee Training Program Licensing” Form with application.)</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualTrainingOptionUpdate(2)}>
                            <span className={appStore.accessIndividualTrainingOption === 2 ? "checked" : ""}></span>
                            <span><strong>Option #2</strong>: All Access Individuals have taken and passed a third-party training course, which has been pre- approved by i-SIGMA. (Submit i-SIGMA “Access Employee Training Program Approval” (AETP) form with application for approval along with an outline of training, or if approval has already been obtained submit the approved copy of the form for review.)</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => accessIndividualTrainingOptionUpdate(3)}>
                            <span className={appStore.accessIndividualTrainingOption === 3 ? "checked" : ""}></span>
                            <span><strong>Option #3</strong>: All Access Individuals have taken and passed an in-house training, which has been pre- approved by i-SIGMA. (Submit i-SIGMA “Access Employee Training Program Approval” (AETP) form with application for approval along with an outline of training, or if approval has already been obtained submit the approved copy of the form for review.)</span>
                        </label>
                    </p>

                    <h3>1.13 ACCESS INDIVIDUAL IDENTIFICATION ON DUTY</h3>
                    <h3>1.14 UNIFORMED FIELD ACCESS INDIVIDUALS</h3>
                    <h3>1.15 RECEIPT OF MEDIA ACCEPTANCE</h3>
                    <h3>1.16 VEHICLE ROADWORTHINESS</h3>
                    <h3>1.17 VEHICLE LOCKS</h3>
                    <h3>1.18 DATA SUBJECT RESPONSE POLICY</h3>
                    <h3>1.19 VERIFICATION OF ENTITY LEGAL STATUS / OWNERSHIP</h3>
                    <h3>1.20 TRANSFER OF CUSTODY (OF UNDESTROYED DATA CONTROLLER MEDIA)</h3>
                    <p>Indicate any categories of subcontractors to which Applicant transfers custody of undestroyed Data Controller Media.</p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => transferOfCustodyOptionUpdate(1)}>
                            <span className={appStore.transferOfCustodyOption === 1 ? "checked" : ""}></span>
                            <span><strong>Option #1</strong>: Temporary Staffing</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => transferOfCustodyOptionUpdate(2)}>
                            <span className={appStore.transferOfCustodyOption === 2 ? "checked" : ""}></span>
                            <span><strong>Option #2</strong>: Transportation (of media prior to destruction)</span>
                        </label>
                    </p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => transferOfCustodyOptionUpdate(3)}>
                            <span className={appStore.transferOfCustodyOption === 3 ? "checked" : ""}></span>
                            <span><strong>Option #3</strong>: Other</span>
                        </label>
                    </p>
                    {appStore.transferOfCustodyOption === 3 &&
                        <label>Please specify:<br/>
                        <input
                            type="text"
                            value={appStore.transferOfCustodyOther}
                            onChange={(event) => transferOfCustodyOtherUpdate(event.target.value)} />
                        </label>
                    }
                    <h3>1.21 TRANSPARENCY IN BIDDING</h3>
                    <h3>1.22 VEHICLE INSPECTION REQUIREMENTS</h3>
                    <h3>1.23 DRIVER TWO-WAY COMMUNICATIONS</h3>
                    <h3>1.24 RESPONSIBLE CARE DURING CUSTODY</h3>
                    <h3>1.25 PERSONAL PHOTOGRAPHIC / ELECTRONIC EQUIPMENT POLICY REQUIREMENT</h3>
                    <h3>1.26 VEHICLE SECURITY</h3>
                    <h3>1.27 DESIGNATION OF A DATA PROTECTION OFFICER (DPO)</h3>
                    <label>Designated DPO Officer:<br/>
                    <input
                        type="text"
                        value={appStore.designatedDPO}
                        onChange={(event) => designatedDPOUpdate(event.target.value)} />
                    </label>
                    <label>DPO Officer Email Address:<br/>
                    <input
                        type="email"
                        value={appStore.designatedDPOemail}
                        onChange={(event) => designatedDPOemailUpdate(event.target.value)} />
                    </label>
                    <h3>1.28 DESIGNATION OF AN i-SIGMA CERTIFICATION COMPLIANCE OFFICER (ICCO)</h3>
                    <label>Designated ICCO Officer:<br/>
                    <input
                        type="text"
                        value={appStore.designatedICCO}
                        onChange={(event) => designatedICCOUpdate(event.target.value)} />
                    </label>
                    <label>ICCO Officer Email:<br/>
                    <input
                        type="text"
                        value={appStore.designatedICCOemail}
                        onChange={(event) => designatedICCOemailUpdate(event.target.value)} />
                    </label>

                    <div className="nextPrevSection">
                        <div>
                            <Prev onClick={prevPage} buttonText="Back"/>
                        </div>
                        <div>
                            <Next onClick={nextSection} buttonText="Next"/>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='section1'>

                </div>
            )
        }

    })
    const Section2 = observer(() => {
        const [display, setDisplay] = useState(false);
        useEffect(() => {
            window.scrollTo(0, 0)
            if (appStore.checkToken){
                checkAuthToken(userStore.token,function(result:boolean){
                    if (!result){
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                    else {
                        setDisplay(true);
                    }
                })
            }
            else {
                setDisplay(true);
            }
        }, [])
        const prevSection = () => {
            appStore.checkToken=true;
            appStore.substep="section1"
        }
        const nextSection = () => {
            appStore.checkToken=true;
            appStore.substep="section4"
        }

        if (display){
            return (
                <div className='section2'>
                    <h2>SECTION 2: SPECIFICATIONS APPLICABLE TO <em>FACILITY-BASED</em> NAID AAA CERTIFICATION OPERATIONS</h2>
                    <h3>2.1 ACCESS CONTROL</h3>
                    <h3>2.2 VISITOR LOG REQUIREMENT</h3>
                    <h3>2.3 SECURED AREA IN MULTI-USE FACILITIES</h3>
                    <h3>2.4 FACILITY INTRUSION &amp; FIRE DETECTION</h3>
                    <h3>2.5 CLOSED CIRCUIT IMAGE CAPTURE</h3>
                    <h3>2.6 COLLECTION-ONLY FACILITY REQUIREMENTS</h3>
                    <h3>2.7 OPERATIONAL SECURITY LOGS</h3>
                    <div className="nextPrevSection">
                        <div>
                            <Prev onClick={prevSection} buttonText="Back"/>
                        </div>
                        <div>
                            <Next onClick={nextSection} buttonText="Next"/>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='section2'>

                </div>
            )
        }

    })
    const Section4 = observer(() => {
        const [display, setDisplay] = useState(false);
        useEffect(() => {
            window.scrollTo(0, 0)
            if (appStore.checkToken){
                checkAuthToken(userStore.token,function(result:boolean){
                    if (!result){
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                    else {
                        setDisplay(true);
                    }
                })
            }
            else {
                setDisplay(true);
            }
        }, [])

        const prevSection = () => {
            appStore.errors=[]
            appStore.checkToken = true;
            if (!appStore.programs.facilityBased){
                appStore.substep="section1";
            }
            else {
                appStore.substep="section2";
            }
        }
        const nextSection = () => {
            appStore.errors = [];
            if (appStore.endorsements.paper){
                if (!appStore.paperFacilityBased && !appStore.paperMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.1')
                }
                if (!appStore.paperContinuousShred && !appStore.paperCrossCut && !appStore.paperPulDisHamm && !appStore.paperUnspecified){
                    appStore.errors.push("Error: Please select at least one type of equipment / methodology under 4.1")
                }
                if (appStore.paperUnspecified){
                    if (appStore.paperUnspecifiedField === ""){
                        appStore.errors.push("Error: Please specify the unlisted type of equipment / methodology under 4.1")
                    }
                }
                if(appStore.paperOnsiteOrFacility === ""){
                    appStore.errors.push("Error: Please specify the type of the primary equipment under 4.1")
                }
                if(appStore.paperManufacturer === ""){
                    appStore.errors.push("Error: Please specify the manufacturer of the primary equipment under 4.1")
                }
                if(appStore.paperModel === ""){
                    appStore.errors.push("Error: Please specify the model of the primary equipment under 4.1")
                }
                if(appStore.paperSerial === ""){
                    appStore.errors.push("Error: Please specify the serial of the primary equipment under 4.1")
                }
                if(appStore.paperHorsepower === ""){
                    appStore.errors.push("Error: Please specify the horsepower of the primary equipment under 4.1")
                }
                if(appStore.paperAdditionalEquipment){
                    if (!appStore.paperAdditionalFileName){
                        appStore.errors.push("Error: Please ensure a file is uploaded with the list of additional equipment information, or uncheck the box under 4.1")
                    }
                }
            }

            if (appStore.endorsements.microMedia){
                if (!appStore.microFacilityBased && !appStore.microMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.2')
                }

                if(appStore.microOnsiteOrFacility === ""){
                    appStore.errors.push("Error: Please specify the type of the primary equipment under 4.2")
                }
                if(appStore.microManufacturer === ""){
                    appStore.errors.push("Error: Please specify the manufacturer of the primary equipment under 4.2")
                }
                if(appStore.microModel === ""){
                    appStore.errors.push("Error: Please specify the model of the primary equipment under 4.2")
                }
            }

            if (appStore.endorsements.hardDrives){
                if (!appStore.hardDriveFacilityBased && !appStore.hardDriveMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.3')
                }
                if(appStore.hardDriveMethod === ""){
                    appStore.errors.push("Error: Please specify the hard drive physical destruction method under 4.3")
                }
            }

            if (appStore.endorsements.solidState){
                if (!appStore.solidStateFacilityBased && !appStore.solidStateMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.4')
                }
                if(appStore.solidStateMethod === ""){
                    appStore.errors.push("Error: Please specify the solid-state device destruction method under 4.3")
                }
            }

            if (appStore.endorsements.nonPaper){
                if (!appStore.nonPaperFacilityBased && !appStore.nonPaperMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.5')
                }
                if (!appStore.nonPaperOpticalMedia && !appStore.nonPaperMagneticTape && !appStore.nonPaperOther){
                    appStore.errors.push("Error: Please specify at least one type of non-paper media destroyed under 4.5")
                }
                else if (appStore.nonPaperOther){
                    if (appStore.nonPaperOtherField === ""){
                        appStore.errors.push("Error: Please specify the other type of non-paper media destroyed under 4.5")
                    }
                }
                if(appStore.nonPaperMethod === ""){
                    appStore.errors.push("Error: Please specify the non-paper media destruction method under 4.5")
                }
            }

            if(appStore.endorsements.product){
                if (!appStore.productFacilityBased && !appStore.productMobileOnsite){
                    appStore.errors.push('Error: Please select at least one service platform under 4.18')
                }
            }

            if (appStore.errors.length === 0){
                appStore.loading=true;
                checkAuthToken(userStore.token, (result:boolean)=>{
                    if (result){
                        const fields = {
                            "options": [{
                                "Name": "F_4_1PPMFacility__c",
                                "Value": (appStore.paperFacilityBased ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMMobileOnsite__c",
                                "Value": (appStore.paperMobileOnsite ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMContinuousShred__c",
                                "Value": (appStore.paperContinuousShred ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMCrossCutPierce_Tear__c",
                                "Value": (appStore.paperCrossCut ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMDisintOrHammermill__c",
                                "Value": (appStore.paperPulDisHamm ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMUnspecifiedEquipment__c",
                                "Value": (appStore.paperUnspecified ? "True" : "False")
                            },
                            {
                                "Name": "F_4_1PPMEquipmentType__c",
                                "Value": appStore.paperUnspecifiedField
                            },
                            {
                                "Name": "F_4_1PPMEquipOperation__c",
                                "Value": appStore.paperOnsiteOrFacility
                            },
                            {
                                "Name": "F_4_1PPMEquipManufacturer__c",
                                "Value": appStore.paperManufacturer
                            },
                            {
                                "Name": "F_4_1PPMEquipModel__c",
                                "Value": appStore.paperModel
                            },
                            {
                                "Name": "F_4_1PPMEquipSerial__c",
                                "Value": appStore.paperSerial
                            },
                            {
                                "Name": "F_4_1PPMEquipHorsepower__c",
                                "Value": appStore.paperHorsepower
                            },
                            {
                                "Name": "F_4_1PPMEquipUploaded__c",
                                "Value": (appStore.paperAdditionalEquipment ? "True" : "False")
                            },
                            {
                                "Name": "F_4_2MMFacility__c",
                                "Value": (appStore.microFacilityBased ? "True" : "False")
                            },
                            {
                                "Name": "F_4_2MMMobileOnsite__c",
                                "Value": (appStore.microMobileOnsite ? "True" : "False")
                            },
                            {
                                "Name": "F_4_2MMEquipOperation__c",
                                "Value": appStore.microOnsiteOrFacility
                            },
                            {
                                "Name": "F_4_2MMEquipManufacturer__c",
                                "Value": appStore.microManufacturer
                            },
                            {
                                "Name": "F_4_2MMEquipModel__c",
                                "Value": appStore.microModel
                            },
                            {
                                "Name": "F_4_3PCHDFacility__c",
                                "Value": (appStore.hardDriveFacilityBased ? "True" : "False")
                            },
                            {
                                "Name": "F_4_3PCHDMobileOnsite__c",
                                "Value": (appStore.hardDriveMobileOnsite ? "True" : "False")
                            },
                            {
                                "Name": "F_4_3PCHDMethod__c",
                                "Value": appStore.hardDriveMethod
                            },
                            {
                                "Name": "F_4_4SSDFacility__c",
                                "Value": appStore.solidStateFacilityBased
                            },
                            {
                                "Name": "F_4_4SSDMobileOnsite__c",
                                "Value": appStore.solidStateMobileOnsite
                            },
                            {
                                "Name": "F_4_4SSDMethod__c",
                                "Value": appStore.solidStateMethod
                            },
                            {
                                "Name": "F_4_5NPMFacility__c",
                                "Value": (appStore.nonPaperFacilityBased ? "True" : "False")
                            },
                            {
                                "Name": "F_4_5NPMMobileOnsite__c",
                                "Value": (appStore.nonPaperMobileOnsite ? "True" : "False")
                            },
                            {
                                "Name": "F_4_4SSDMethod__c",
                                "Value": appStore.solidStateMethod
                            },
                            {
                                "Name": "F_4_5NPMOptical__c",
                                "Value": appStore.nonPaperOpticalMedia
                            },
                            {
                                "Name": "F_4_5NPMMagTape__c",
                                "Value": appStore.nonPaperMagneticTape
                            },
                            {
                                "Name": "F_4_5NPMOther__c",
                                "Value": appStore.nonPaperOther
                            },
                            {
                                "Name": "F_4_5NPMOtherSpecified__c",
                                "Value": appStore.nonPaperOtherField
                            },
                            {
                                "Name": "F_4_5NPMMethod__c",
                                "Value": appStore.nonPaperMethod
                            },
                            {
                                "Name": "F_4_18PDFacility__c",
                                "Value": appStore.productFacilityBased
                            },
                            {
                                "Name": "F_4_18PDMobileOnsite__c",
                                "Value": appStore.productMobileOnsite
                            }
                            ]
                        }
                        updateOrganization( userStore.token, appStore.orgID, fields, (response:any)=>{
                            appStore.checkToken = false;
                            appStore.substep = "section5"
                            appStore.loading=false;
                        })
                    }
                    else {
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                });
            }
            else {
                window.scrollTo(0,0)
            }
        }

        const paperFacilityBasedUpdate = () => {
            appStore.paperFacilityBased = (appStore.paperFacilityBased ? false : true)
        }
        const paperMobileOnsiteUpdate = () => {
            appStore.paperMobileOnsite = (appStore.paperMobileOnsite ? false : true)
        }
        const paperContinuousShredUpdate = () => {
            appStore.paperContinuousShred = (appStore.paperContinuousShred ? false : true)
        }
        const paperCrossCutUpdate = () => {
            appStore.paperCrossCut = (appStore.paperCrossCut ? false : true)
        }
        const paperPulDisHammUpdate = () => {
            appStore.paperPulDisHamm = (appStore.paperPulDisHamm ? false : true)
        }
        const paperUnspecifiedUpdate = () => {
            appStore.paperUnspecified = (appStore.paperUnspecified ? false : true)
        }
        const paperUnspecifiedFieldUpdate = (value:string) => {
            appStore.paperUnspecifiedField = value;
        }
        const paperOnsiteOrFacilityUpdate = (value:string) => {
            appStore.paperOnsiteOrFacility = value;
        }
        const paperManufacturerUpdate = (value:string) => {
            appStore.paperManufacturer = value;
        }
        const paperModelUpdate = (value:string) => {
            appStore.paperModel = value;
        }
        const paperSerialUpdate = (value:string) => {
            appStore.paperSerial = value;
        }
        const paperHorsepowerUpdate = (value:string) => {
            appStore.paperHorsepower = value;
        }
        const paperAdditionalEquipmentUpdate = () => {
            appStore.paperAdditionalEquipment = (appStore.paperAdditionalEquipment ? false : true)
        }
        const paperAdditionalEquipmentFileUpdate = (file:File) => {
            if (file.type === "application/pdf"){
                appStore.paperAdditionalFileName = file.name;
                appStore.paperAdditionalFile = file;
            }
            else {
                alert("Files must be uploaded in PDF format");
            }
        }

        const microFacilityBasedUpdate = () => {
            appStore.microFacilityBased = (appStore.microFacilityBased ? false : true)
        }
        const microMobileOnsiteUpdate = () => {
            appStore.microMobileOnsite = (appStore.microMobileOnsite ? false : true)
        }
        const microOnsiteOrFacilityUpdate = (value:string) => {
            appStore.microOnsiteOrFacility = value;
        }
        const microManufacturerUpdate = (value:string) => {
            appStore.microManufacturer = value;
        }
        const microModelUpdate = (value:string) => {
            appStore.microModel = value;
        }

        const hardDriveFacilityBasedUpdate = () => {
            appStore.hardDriveFacilityBased = (appStore.hardDriveFacilityBased ? false : true)
        }
        const hardDriveMobileOnsiteUpdate = () => {
            appStore.hardDriveMobileOnsite = (appStore.hardDriveMobileOnsite ? false : true)
        }
        const hardDriveMethodUpdate = (value:string) => {
            appStore.hardDriveMethod = value;
        }

        const solidStateFacilityBasedUpdate = () => {
            appStore.solidStateFacilityBased = (appStore.solidStateFacilityBased ? false : true)
        }
        const solidStateMobileOnsiteUpdate = () => {
            appStore.solidStateMobileOnsite = (appStore.solidStateMobileOnsite ? false : true)
        }
        const solidStateMethodUpdate = (value:string) => {
            appStore.solidStateMethod = value;
        }

        const nonPaperFacilityBasedUpdate = () => {
            appStore.nonPaperFacilityBased = (appStore.nonPaperFacilityBased ? false : true)
        }
        const nonPaperMobileOnsiteUpdate = () => {
            appStore.nonPaperMobileOnsite = (appStore.nonPaperMobileOnsite ? false : true)
        }
        const nonPaperOpticalMediaUpdate = () => {
            appStore.nonPaperOpticalMedia = (appStore.nonPaperOpticalMedia ? false : true)
        }
        const nonPaperMagneticTapeUpdate = () => {
            appStore.nonPaperMagneticTape = (appStore.nonPaperMagneticTape ? false : true)
        }
        const nonPaperOtherUpdate = () => {
            appStore.nonPaperOther = (appStore.nonPaperOther ? false : true)
        }
        const nonPaperOtherFieldUpdate = (value:string) => {
            appStore.nonPaperOtherField = value;
        }
        const nonPaperMethodUpdate = (value:string) => {
            appStore.nonPaperMethod = value;
        }

        const productFacilityBasedUpdate = () => {
            appStore.productFacilityBased = (appStore.productFacilityBased ? false : true)
        }
        const productMobileOnsiteUpdate = () => {
            appStore.productMobileOnsite = (appStore.productMobileOnsite ? false : true)
        }

        if (display){
            return (
                <div className='section4'>
                    <h2>SECTION 4: ADDITIONAL SPECIFICATIONS APPLICABLE TO NAID AAA CERTIFICATION MEDIA DESTRUCTION</h2>

                    {appStore.endorsements.paper &&
                        <div>
                            <h3>4.1 PAPER / PRINTED MEDIA PHYSICAL DESTRUCTION ENDORSEMENT</h3>
                            <p>Select Service Platform offered:</p>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperFacilityBasedUpdate()}>
                                    <span className={appStore.paperFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperMobileOnsiteUpdate()}>
                                    <span className={appStore.paperMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                            <p>Select Paper Media Destruction Equipment / Methodology:</p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperContinuousShredUpdate()}>
                                    <span className={appStore.paperContinuousShred ? "checked" : ""}></span>
                                    Continuous Shred: Width (max): 5/8 inch; Length: Indefinite
                                </label>
                            </p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperCrossCutUpdate()}>
                                    <span className={appStore.paperCrossCut ? "checked" : ""}></span>
                                    Cross Cut or Pierce &amp; Tear: Width (max): 3/4 inch; Length (max): 2.5 inches
                                </label>
                            </p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperPulDisHammUpdate()}>
                                    <span className={appStore.paperPulDisHamm ? "checked" : ""}></span>
                                    Pulverizer, Disintegrator or Hammermill*: Screen Size (max): 2-inch diameter holes
                                </label>
                            </p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperUnspecifiedUpdate()}>
                                    <span className={appStore.paperUnspecified ? "checked" : ""}></span>
                                    Unspecified Equipment
                                </label>
                            </p>
                            {appStore.paperUnspecified &&
                                <label>Please describe the type of equipment and cutting mechanism specifications (screen hole size*, blade width, etc.):<br/>
                                <input
                                    type="text"
                                    value={appStore.paperUnspecifiedField}
                                    onChange={(event) => paperUnspecifiedFieldUpdate(event.target.value)} />
                                </label>
                            }
                            <p>Please provide the following information regarding the primary paper / printed media destruction equipment:</p>
                            <label>Mobile / Onsite or Facility Equipment<br/>
                            <input
                                type="text"
                                value={appStore.paperOnsiteOrFacility}
                                onChange={(event) => paperOnsiteOrFacilityUpdate(event.target.value)} />
                            </label>
                            <label>Manufacturer<br/>
                            <input
                                type="text"
                                value={appStore.paperManufacturer}
                                onChange={(event) => paperManufacturerUpdate(event.target.value)} />
                            </label>
                            <label>Model<br/>
                            <input
                                type="text"
                                value={appStore.paperModel}
                                onChange={(event) => paperModelUpdate(event.target.value)} />
                            </label>
                            <label>Serial #<br/>
                            <input
                                type="text"
                                value={appStore.paperSerial}
                                onChange={(event) => paperSerialUpdate(event.target.value)} />
                            </label>
                            <label>Horsepower<br/>
                            <input
                                type="text"
                                value={appStore.paperHorsepower}
                                onChange={(event) => paperHorsepowerUpdate(event.target.value)} />
                            </label>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => paperAdditionalEquipmentUpdate()}>
                                    <span className={appStore.paperAdditionalEquipment ? "checked" : ""}></span>
                                    Please check box if providing a list of additional equipment information
                                </label>
                            </p>
                            {appStore.paperAdditionalEquipment &&
                                <div>
                                    <p>Current Uploaded File: {(!appStore.paperAdditionalFileName?"No File Uploaded":appStore.paperAdditionalFileName)}</p>
                                    <FileUpload proceed={paperAdditionalEquipmentFileUpdate} accept="application/pdf" />
                                </div>
                            }
                        </div>
                    }

                    {appStore.endorsements.microMedia &&
                        <div>
                            <h3>4.2 MICRO MEDIA PHYSICAL DESTRUCTION ENDORSEMENT</h3>
                            <p>Select Service Platform offered:</p>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => microFacilityBasedUpdate()}>
                                    <span className={appStore.microFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => microMobileOnsiteUpdate()}>
                                    <span className={appStore.microMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                            <p>Please provide the following information regarding the micro media destruction equipment:</p>
                            <label>Mobile / Onsite or Facility Equipment<br/>
                            <input
                                type="text"
                                value={appStore.microOnsiteOrFacility}
                                onChange={(event) => microOnsiteOrFacilityUpdate(event.target.value)} />
                            </label>
                            <label>Manufacturer<br/>
                            <input
                                type="text"
                                value={appStore.microManufacturer}
                                onChange={(event) => microManufacturerUpdate(event.target.value)} />
                            </label>
                            <label>Model<br/>
                            <input
                                type="text"
                                value={appStore.microModel}
                                onChange={(event) => microModelUpdate(event.target.value)} />
                            </label>
                        </div>
                    }
                    {appStore.endorsements.hardDrives &&
                        <div>
                            <h3>4.3 HARD DRIVE PHYSICAL DESTRUCTION ENDORSEMENT</h3>
                            <p>Select Service Platform offered:</p>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => hardDriveFacilityBasedUpdate()}>
                                    <span className={appStore.hardDriveFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => hardDriveMobileOnsiteUpdate()}>
                                    <span className={appStore.hardDriveMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                            <p>Please provide the following information regarding the hard drive destruction equipment:</p>
                            <label>Hard Drive Physical Destruction Method:<br/>
                            <input
                                type="text"
                                value={appStore.hardDriveMethod}
                                onChange={(event) => hardDriveMethodUpdate(event.target.value)} />
                            </label>
                            <p>(e.g. shredding, crushing, piercing)</p>
                        </div>
                    }
                    {appStore.endorsements.solidState &&
                        <div>
                            <h3>4.4 SOLID-STATE DEVICE PHYSICAL DESTRUCTION ENDORSEMENT</h3>
                            <p>Select Service Platform offered:</p>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => solidStateFacilityBasedUpdate()}>
                                    <span className={appStore.solidStateFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => solidStateMobileOnsiteUpdate()}>
                                    <span className={appStore.solidStateMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                            <p>Please provide the following information regarding the Solid-State Device destruction equipment:</p>
                            <label>Solid-State Device Physical Destruction Method:<br/>
                            <input
                                type="text"
                                value={appStore.solidStateMethod}
                                onChange={(event) => solidStateMethodUpdate(event.target.value)} />
                            </label>
                            <p>(e.g. shredding, crushing, piercing)</p>
                        </div>
                    }
                    {appStore.endorsements.nonPaper &&
                        <div>
                            <h3>4.5 NON-PAPER PHYSICAL DESTRUCTION ENDORSEMENT</h3>
                            <p>Select Service Platform offered:</p>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => nonPaperFacilityBasedUpdate()}>
                                    <span className={appStore.nonPaperFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => nonPaperMobileOnsiteUpdate()}>
                                    <span className={appStore.nonPaperMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                            <p>Please provide the following information regarding the Non-Paper Media destruction equipment:</p>
                            <p>Types of Non-Paper Media physically destroyed:</p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => nonPaperOpticalMediaUpdate()}>
                                    <span className={appStore.nonPaperOpticalMedia ? "checked" : ""}></span>
                                    Optical Media
                                </label>
                            </p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => nonPaperMagneticTapeUpdate()}>
                                    <span className={appStore.nonPaperMagneticTape ? "checked" : ""}></span>
                                    Magnetic Tape Media
                                </label>
                            </p>
                            <p>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => nonPaperOtherUpdate()}>
                                    <span className={appStore.nonPaperOther ? "checked" : ""}></span>
                                    Other
                                </label>
                            </p>
                            {appStore.nonPaperOther &&
                                <label>Please Specify:<br/>
                                <input
                                    type="text"
                                    value={appStore.nonPaperOtherField}
                                    onChange={(event) => nonPaperOtherFieldUpdate(event.target.value)} />
                                </label>
                            }
                            <label>Non-Paper Media Physical Destruction Method:<br/>
                            <input
                                type="text"
                                value={appStore.nonPaperMethod}
                                onChange={(event) => nonPaperMethodUpdate(event.target.value)} />
                            </label>
                            <p>(e.g. shredding, disintegration, incineration)</p>
                        </div>
                    }
                    {(appStore.endorsements.hardDrives || appStore.endorsements.solidState) &&
                        <div>
                            <h3>4.14 UNIQUE IDENTIFIER TRACKING (Applicable for Hard Drive and SSD Destruction Only)</h3>
                            <h3>4.16 RESPONSIBLE DISPOSAL OF DESTROYED ELECTRONIC WASTE (Applicable for Hard Drive and SSD Destruction Only)</h3>
                            <h3>4.17 ELECTRONIC RECYCLING PERMIT COMPLIANCE (Applicable for Hard Drive and SSD Destruction Only)</h3>
                        </div>
                    }
                    {appStore.endorsements.product &&
                        <div>
                            <h3>4.18 PRODUCT DESTRUCTION ENDORSEMENT</h3>
                            <div className='fancyCheckboxLine'>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => productFacilityBasedUpdate()}>
                                    <span className={appStore.productFacilityBased ? "checked" : ""}></span>
                                    Facility-Based Destruction
                                </label>
                                <label className="fancyCheckbox indentedCheckbox" onClick={() => productMobileOnsiteUpdate()}>
                                    <span className={appStore.productMobileOnsite ? "checked" : ""}></span>
                                    Mobile / Onsite
                                </label>
                            </div>
                        </div>
                    }

                    {appStore.programs.transferProc &&
                        <h3>4.19 TRANSFER PROCESSING STATION (COMPLETED IN SECTION 3)</h3>
                    }

                    <h3>4.20 QUALITY CONTROL MONITORING OF DESTRUCTION PROCESS</h3>
                    <h3>4.21 RESPONSIBLE DISPOSAL REQUIREMENT</h3>

                    {appStore.programs.mobileOnsite &&
                        <h3>4.23 ON PREMISES DESTRUCTION REQUIREMENT (Mobile / Onsite Service Platform Only)</h3>
                    }

                    <h3>4.24(N) GENERAL LIABILITY COVERAGE</h3>

                    <div className="nextPrevSection">
                        <div>
                            <Prev onClick={prevSection} buttonText="Back"/>
                        </div>
                        <div>
                            <Next onClick={nextSection} buttonText="Next"/>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='section4'>

                </div>
            )
        }
    })
    const Section5 = observer(() => {
        const [display, setDisplay] = useState(false);
        useEffect(() => {
            window.scrollTo(0, 0)
            if (appStore.checkToken){
                checkAuthToken(userStore.token,function(result:boolean){
                    if (!result){
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    }
                    else {
                        setDisplay(true);
                    }
                })
            }
            else {
                setDisplay(true);
            }
        }, [])
        const prevSection = ()=>{
            appStore.errors=[]
            appStore.checkToken = true
            appStore.substep="section4"
        }
        async function nextPage(){
            appStore.errors=[];
            if (!appStore.agree){
                appStore.errors.push('Error: You cannot complete the application process without agreeing to the statement below')
                window.scrollTo(0,0)
            }
            else {
                //Determine the certification fees required
                const international = (appStore.orgCountry === "US" || appStore.orgCountry === "CA" ? false : true )
                appStore.lineItems = [];

                const availableCertifications = {
                    international:{
                        facility:"3ff355cb-00e3-cd1e-9131-0b3ba9724707",
                        mobile:"3ff355cb-00e3-cb61-b6ce-0b3ba9722673",
                        facilityMobile:"3ff355cb-00e3-c7b0-8323-0b3ba9726751",
                        tps:"3ff355cb-00e3-c9ad-68de-0b3ba984bede",
                        tpsMobile:"3ff355cb-00e3-ce67-963b-0b3ba984edda"
                    },
                    northAmerica:{
                        facility:"3ff355cb-00e3-cb46-a544-0b3b8e9e1976",
                        mobile:"3ff355cb-00e3-c53b-9c43-0b3b8e9ef3f3",
                        facilityMobile:"3ff355cb-00e3-cfb7-2a11-0b3ba96fc242",
                        tps:"3ff355cb-00e3-ce29-7799-0b3ba984a9ff",
                        tpsMobile:"3ff355cb-00e3-c849-23f2-0b3ba984d838"
                    }
                }
                //Transfer Location - Can't be a Facility Based
                if (appStore.programs.transferProc && !appStore.programs.facilityBased){
                    //Is also Mobile
                    if (appStore.programs.mobileOnsite){
                        if (international){
                            appStore.lineItems.push(availableCertifications.international.tpsMobile)
                        }
                        else {
                            appStore.lineItems.push(availableCertifications.northAmerica.tpsMobile)
                        }
                    }
                    else {
                        if (international){
                            appStore.lineItems.push(availableCertifications.international.tps)
                        }
                        else {
                            appStore.lineItems.push(availableCertifications.northAmerica.tps)
                        }
                    }
                }
                //Facility Based - Can't be a Transfer Location
                else if (appStore.programs.facilityBased && !appStore.programs.transferProc){
                    //Is also Mobile
                    if (appStore.programs.mobileOnsite){
                        if (international){
                            appStore.lineItems.push(availableCertifications.international.facilityMobile)
                        }
                        else {
                            appStore.lineItems.push(availableCertifications.northAmerica.facilityMobile)
                        }
                    }
                    else {
                        if (international){
                            appStore.lineItems.push(availableCertifications.international.facility)
                        }
                        else {
                            appStore.lineItems.push(availableCertifications.northAmerica.facility)
                        }
                    }
                }
                //Neither Facility based or Transfer Location, but Is Mobile
                else if (!appStore.programs.facilityBased && !appStore.programs.transferProc && appStore.programs.mobileOnsite){
                    if (international){
                        appStore.lineItems.push(availableCertifications.international.mobile)
                    }
                    else {
                        appStore.lineItems.push(availableCertifications.northAmerica.mobile)
                    }
                }

                if (appStore.lineItems.length > 0){
                    appStore.loading = true;
                    checkAuthToken(userStore.token,function(result:boolean){
                        if (result){
                            appStore.checkToken = false
                            var certFeeWhere = ""
                            appStore.lineItems.forEach(lineItemId => {
                                certFeeWhere += "ID = '{"+lineItemId+"}' OR ";
                            });
                            certFeeWhere = certFeeWhere.substring(0, certFeeWhere.length - 4);
                            let certurl = new URL(membersuiteURL+"/certifications/v1/certificationfees/"+tenantId)
                            const certFeeMSQL = "SELECT Name, Price FROM CertificationFee WHERE "+certFeeWhere
                            certurl.searchParams.append("page", "1");
                            certurl.searchParams.append("pageSize", "100");
                            certurl.searchParams.append("msql", certFeeMSQL);

                            const certFeeHeaders = new Headers();
                            certFeeHeaders.append("Authorization", userStore.token);

                            const certFeeRequestOptions = {
                                method: 'GET',
                                headers: certFeeHeaders
                            };

                            interface Fee {
                                name:string,
                                price:number
                            }
                            fetch(certurl.toString(),certFeeRequestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                if (result.length > 0){
                                    appStore.totalFees = 0;
                                    appStore.feeNames = "";
                                    result.forEach((fees:Fee) => {
                                        appStore.feeNames += fees.name
                                        appStore.totalFees += fees.price
                                    });
                                    appStore.step++;
                                    appStore.loading = false;
                                }
                                else {
                                    appStore.feeNames = "";
                                    appStore.totalFees = 0;
                                    appStore.step++;
                                    appStore.loading = false;
                                }
                            }).catch(error => {
                                console.log(error)
                                alert('E105: Could Not Fetch Record. Try again later.')
                                window.location.replace(`${process.env.PUBLIC_URL}`);
                            });
                        }
                        else {
                            window.location.replace(`${process.env.PUBLIC_URL}`);
                        }
                    });
                }
                else {
                    appStore.checkToken = true
                    appStore.totalFees = 0;
                    appStore.feeNames = "";
                    appStore.step++;
                }
            }
        }
        const agreeUpdate = () => {
            appStore.agree = (appStore.agree ? false : true)
        }
        if (display){
            return (
                <div>
                    <h2>SECTION 5: AGREEMENT</h2>
                    <p>By checking the box below and submitting this online application, I represent that I have the authority as an agent of the firm applying for NAID AAA Certification and affirm it meets all requirements of thereof and, in choosing the i-SIGMA Audit Regime, agree to ongoing scheduled and unannounced audits to verify such compliance and agree to abide by the Terms and Conditions as stipulated in the i-SIGMA Certification Specifications Reference Manual.</p>
                    <p>
                        <label className="fancyCheckbox indentedCheckbox" onClick={() => agreeUpdate()}>
                            <span className={appStore.agree ? "checked" : ""}></span>
                            I Agree
                        </label>
                    </p>
                    <div className="nextPrevSection">
                        <div>
                            <Prev onClick={prevSection} buttonText="Back"/>
                        </div>
                        <div>
                            <Next onClick={nextPage} buttonText="Next"/>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>

                </div>
            )
        }
    })

    return (
        <div className="step4">
            <h1>Part 4.) Required NAID AAA Specifications</h1>
            <p>Specification details and audit methodology are provided in the i-SIGMA Certification Specifications Reference Manual available free of charge on the i-SIGMA website.</p>
            <p>The requirement to meet some specifications will be dependent on the nature of service platforms (Facility-based or Mobile / Onsite) and / or the media destruction Endorsement(s) sought by the applicant</p>
            <p>Some specifications referenced below are followed by questions that must be completed as part of the application submission.</p>
            <p>Please <a target='_blank' rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/downloads/i-SIGMA Certification Specifications Reference Manual_0821H.pdf`}>download and refer to the manual</a> while answering the following questions.</p>
            <ErrorDisplay/>
            {appStore.substep === "section1" &&
                <Section1/>
            }
            {appStore.substep === "section2" &&
                <Section2/>
            }
            {appStore.substep === "section4" &&
                <Section4/>
            }
            {appStore.substep === "section5" &&
                <Section5/>
            }
        </div>
    )
})