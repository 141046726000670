import React, {useContext} from 'react'
import GoogleTranslate from './GoogleTranslate'
import { AppStoreContext, UserStoreContext } from "../stores/AppStore";

export default function Header() {
    const userStore = useContext(UserStoreContext);
    const appStore = useContext(AppStoreContext);

    const loadDashboard = (event:any) => {
        event.preventDefault();
        if (appStore.page !== "Dashboard"){
            appStore.loading = true;
            appStore.page = "Dashboard";
        }
    }

    const logOut = async (event:any) => {
        event.preventDefault();
        /*let response = await fetch('https://directory.isigmaonline.org/app/compliance-admin-logout.php', {
            method: "post",
            body: JSON.stringify({
                jwt: jwt
            })
        })
        await response.json();

        // delete the cookie
        Cookies.remove('jwt');*/

        window.location.reload();
    }

    return (
        <header>
            <div>
                <a className="isigma-logo" href="https://isigmaonline.org" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/isigma-grey-logo.png`} alt="i-SIGMA Logo" /></a>
                <div className="flexEnd">
                    <span className="title">i-SIGMA Certification Application Portal</span>
                    <GoogleTranslate />
                </div>
            </div>
                {userStore.token !== '' &&
                    <div className="btnGroup">
                        <button className='btn admin-btn' onClick={(event)=>loadDashboard(event)}>My Profile</button>
                        <button className="btn admin-btn" onClick={(event)=>logOut(event)}>Log Out</button>
                        <div>
                            <a className="reportProblem" href="https://isigmaonline.org/about/about-contact/" rel="noopener noreferrer" target="_blank" >Report A Problem</a>
                        </div>
                    </div>
                }
                {userStore.token === '' &&
                    <div className="btnGroup">
                        <div>
                            <a className="reportProblem" href="https://isigmaonline.org/about/about-contact/" rel="noopener noreferrer" target="_blank" >Report A Problem</a>
                        </div>
                    </div>
                }
        </header>
    )
}