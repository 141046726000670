import React, { useEffect, useState, useContext } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext } from "../stores/AppStore";
import { checkAuthToken } from '../api/API';
import { scriptsURL } from '../api/APICredentials';
import { ErrorDisplay } from './ErrorDisplay'
import { AdminNext } from './NavButtons';

export const Login = observer(() => {

    const userStore = useContext(UserStoreContext);
    const appStore = useContext(AppStoreContext);

    const [usernameValue, setUsername] = useState('');
    const [passwordValue, setPassword] = useState('');

    //const [passwordResetValue, setReset] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
        checkAuthToken(userStore.token,function(result:boolean){
            if (result){
                appStore.page = "Dashboard";
            }
        })
    },[])

    const handelSubmit = async (event: any) => {
        event.preventDefault();
        appStore.errors=[];
        appStore.loading=true;
        if (usernameValue !== '' && passwordValue !== ''){
            const formData = new FormData();
            formData.append('username',usernameValue)
            formData.append('password',passwordValue)
            const tokenRequestOptions = {
                method: 'POST',
                body: formData
            };

            fetch(scriptsURL+'/generateToken.php', tokenRequestOptions)
            .then(response => response.json())
            .then((result)=>{
                if (result.success){
                    userStore.token = "Bearer "+result.response.idToken;
                    appStore.page = "Dashboard";
                }
                else {
                    appStore.errors.push("Error: "+result.error)
                    appStore.loading=false;
                    window.scrollTo(0, 0)
                }
            })
            .catch(error => {
                console.log(error)
                alert('E100: Could Not Start Session. Try again later.')
                window.location.replace(`${process.env.PUBLIC_URL}`);
            });
        }
        else {
            appStore.errors.push("Error: Please add both a username and a password")
            appStore.loading=false;
            window.scrollTo(0, 0)
        }
    }

    /*const handelResetSubmit = async (event: any) => {
        event.preventDefault();
    }*/

    const handleUsernameChange = (value: string) => {
        setUsername(value);
    }

    const handlePasswordChange = (value: string) => {
        setPassword(value);
    }

    /*const handlePasswordResetChange = (value: string) => {
        setReset(value);
    }*/

    /*return (
        <div className="main">
            <div className='row'>
                <h1>Certification Portal</h1>
            </div>
            <ErrorDisplay />
            <div className="row">
                <div className='col-half'>
                    <h2>Login</h2>
                    <p>Please enter your login credentials to access the certification application.</p>
                    <form className="loginForm" onSubmit={(event) => handelSubmit(event)}>
                        <div>
                            <label>Username
                                <input type="text" value={usernameValue} onChange={(event) => handleUsernameChange(event.target.value)} />
                            </label>
                        </div>

                        <div>
                            <label>Password
                                <input type="password" value={passwordValue} onChange={(event) => handlePasswordChange(event.target.value)} />
                            </label>
                        </div>

                        <div>
                        <AdminNext buttonText='Log In' onClick={()=>{}} />
                        </div>
                    </form>
                </div>
                <div className='col-half'>
                    <h2>Forgot Your Password?</h2>
                    <p>Please enter your username and we will send you an email with details on updating your password. If you don't know your username, it is typically your email address with your company. Please contact certification@isigmaonline.org for additional assistance.</p>
                    <form className="loginForm" onSubmit={(event) => handelResetSubmit(event)}>
                        <div>
                            <label>Username
                                <input type="text" value={passwordResetValue} onChange={(event) => handlePasswordResetChange(event.target.value)} />
                            </label>
                        </div>
                        <div>
                            <input className="btn admin-btn" type="submit" value="Update Password" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )*/
    return (
        <div className="main">
            <div className="row justify-center">
                <div className='col-half'>
                    <h1>Certification Portal</h1>
                    <p>Please enter your login credentials to access the certification application. For assistance, please contact certification@isigmaonline.org.</p>
                    <ErrorDisplay />
                    <form className="loginForm" onSubmit={(event) => handelSubmit(event)}>
                        <div>
                            <label>Username
                                <input type="text" value={usernameValue} onChange={(event) => handleUsernameChange(event.target.value)} />
                            </label>
                        </div>

                        <div>
                            <label>Password
                                <input type="password" value={passwordValue} onChange={(event) => handlePasswordChange(event.target.value)} />
                            </label>
                        </div>

                        <div>
                        <AdminNext buttonText='Log In' onClick={()=>{}} />
                        </div>
                    </form>
                </div>
            </div>
            <div className="row justify-center">
                <div className='col-half'>
                    <h4>Forgot Your Password?</h4>
                    <p>If you are having trouble logging in or have forgotten your password, please log into the <a href="https://customer25666bb65.portal.membersuite.com/" target="_blank" rel='noreferrer'>Member Portal</a> and follow the “Forgot your password? Click here” link, found below the “Enter your login information” fields.</p>
                </div>
            </div>
        </div>
    )
})