import {observable, makeObservable} from "mobx"
import { createContext } from "react"
export interface Organization{
    roW_NUMBER:number
    id:string,
    name:string,
    physical_Address_Line1:string,
    physical_Address_City:string,
    physical_Address_State:string,
    physical_Address_PostalCode:string,
    physical_Address_Country:string,
    ysnCertified__c:boolean
}

class UserStore {
    @observable token = ''
    @observable user = ''
    @observable displayName = ''
    @observable email = ''
    @observable organizations = Array<Organization>()
    constructor() {
        makeObservable(this)
    }
}
export interface Individual {
    id:string,
    name:string,
    date:string,
    access:number,
    title:string,
    citizen:number
}
export interface Vehicle {
    id:string,
    type:string,
    vin:string,
    make:string,
    plate:string,
    state:string,
    overnight:string,
    audit:number
}
interface Programs {
    facilityBased:boolean,
    mobileOnsite:boolean,
    transferProc:boolean,
    collectionOnly:boolean
}
interface Endorsements {
    paper:boolean,
    hardDrives:boolean,
    microMedia:boolean,
    solidState:boolean,
    nonPaper:boolean
    product:boolean
}
export interface fDTimeWeek{[key: string]:string}
export const firstDispatchTimeWeekObj:fDTimeWeek = {
    Mo:"",
    Tu:"",
    We:"",
    Th:"",
    Fr:"",
    Sa:"",
    Su:""
}
class AppStore{
    @observable loading = false
    @observable page = ""
    @observable orgID = ""
    @observable step = 0
    @observable substep = ""
    @observable errors = Array<string>()
    @observable checkToken = false
    /*step 1*/
    @observable offerEEDS = 0
    @observable consultingSubsidy = 0
    /*step 2*/
    @observable orgName = ""
    @observable orgPhysicalAddress = ""
    @observable orgSuite = ""
    @observable orgCity = ""
    @observable orgState = ""
    @observable orgCountry = ""
    @observable orgMailCode = ""
    @observable certContact = ""
    @observable email = ""
    @observable phone = ""
    @observable fax = ""
    @observable infoRequest = ""
    //@observable international = false
    /*step 3*/
    @observable individuals = Array<Individual>()
    @observable deletedIndividuals = Array<Individual>()
    @observable checkIndividuals = false;
    @observable numberAccessIndividuals = 0
    @observable vehicles = Array<Vehicle>()
    @observable deletedVehicles = Array<Vehicle>()
    @observable officeHours = ""
    @observable operationHours = ""
    @observable firstDispatch = ""
    @observable firstDispatchTime = ""
    @observable firstDispatchTimeWk=firstDispatchTimeWeekObj
    @observable storedAtOtherAddress = 0
    @observable storedAtOtherAddressInput = ""
    @observable programs:Programs={
        facilityBased:false,
        mobileOnsite:false,
        transferProc:false,
        collectionOnly:false
    }
    @observable endorsements:Endorsements={
        paper:false,
        hardDrives:false,
        microMedia:false,
        solidState:false,
        nonPaper:false,
        product:false
    }
    @observable affiliatedFacilityAddress = ""
    /*step 4 section 1*/
    @observable substanceScreeningOption = 0
    @observable accessIndividualScreeningOption = 0
    @observable accessIndividualScreeningYear = ""
    @observable accessIndividualTrainingOption = 0
    @observable transferOfCustodyOption = 0
    @observable transferOfCustodyOther = ""
    @observable designatedDPO = ""
    @observable designatedDPOemail = ""
    @observable designatedICCO = ""
    @observable designatedICCOemail = ""
    /*step 4 section 4*/
    @observable paperFacilityBased=false
    @observable paperMobileOnsite=false
    @observable paperContinuousShred=false
    @observable paperCrossCut=false
    @observable paperPulDisHamm=false
    @observable paperUnspecified=false
    @observable paperUnspecifiedField=""
    @observable paperOnsiteOrFacility=""
    @observable paperManufacturer=""
    @observable paperModel=""
    @observable paperSerial=""
    @observable paperHorsepower=""
    @observable paperAdditionalEquipment=false
    @observable paperAdditionalFileName=""
    @observable paperAdditionalFile={} as File
    @observable microFacilityBased=false
    @observable microMobileOnsite=false
    @observable microOnsiteOrFacility=""
    @observable microManufacturer=""
    @observable microModel=""
    @observable hardDriveFacilityBased=false
    @observable hardDriveMobileOnsite=false
    @observable hardDriveMethod=""
    @observable solidStateFacilityBased=false
    @observable solidStateMobileOnsite=false
    @observable solidStateMethod=""
    @observable nonPaperFacilityBased=false
    @observable nonPaperMobileOnsite=false
    @observable nonPaperOpticalMedia=false
    @observable nonPaperMagneticTape=false
    @observable nonPaperOther=false
    @observable nonPaperOtherField=""
    @observable nonPaperMethod=""
    @observable productFacilityBased=false
    @observable productMobileOnsite=false
    /*step 4 section 5 */
    @observable agree=false;
    @observable lineItems = Array();
    @observable totalFees=0;
    @observable feeNames="";
    /*step 5*/
    @observable creditCardNumber=""
    @observable expiryMonth= null as null|number
    @observable expiryYear= null as null|number
    @observable nameOnCard=""
    @observable securityCode= null as null|number
    @observable submit = false
    constructor() {
        makeObservable(this)
    }
}

export const AppStoreContext = createContext(new AppStore())
export const UserStoreContext = createContext(new UserStore())