import React, { useEffect, useState, useContext } from 'react';
import { UserStoreContext, AppStoreContext } from "../stores/AppStore";
import { checkAuthToken } from '../api/API';

export const CertVerify = () => {

    const [display, setDisplay] = useState(false);
    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const [codeVersion, setCodeVersion] = useState('color');

    useEffect(() => {

        window.scrollTo(0, 0)
        checkAuthToken(userStore.token,function(result:boolean){
            if (!result){
                window.location.replace(`${process.env.PUBLIC_URL}`);
            }
            else {
                setDisplay(true);

                const existingJQUERY = document.getElementById('jquery');
                if (!existingJQUERY){
                    const jquery = document.createElement("script");
                    jquery.src = "https://code.jquery.com/jquery-3.1.1.min.js";
                    jquery.id = 'jquery';
                    jquery.async = true;
                    jquery.onload = () => loadVerifyScript();
                    document.body.appendChild(jquery);
                }
                else {
                    loadVerifyScript();
                }
            }
        })
    },[])
    function loadVerifyScript(){
        const existingVERIFY = document.getElementById('verify');
        if (existingVERIFY){
            existingVERIFY.remove();
        }
        const script = document.createElement("script");
        script.src = "https://certification.naidonline.org/verify.js";
        script.id = 'verify';
        script.async = true;
        script.onload = () => {
            console.log('loaded');
        };
        document.body.appendChild(script);
    }
    function handleVersionChange(value:string){
        setCodeVersion(value);
        loadVerifyScript();
    }
    interface verifierProps {
        version: string,
        organization: string
    }
    function CertVerifierCode(props:verifierProps){
        const [copyStatus, setCopyStatus] = useState(false);
        const copyStyle = {
            opacity: copyStatus ? 1 : 0
        }
        function copyToClipboard(value:string){
            navigator.clipboard.writeText(value);
            setCopyStatus(true);
            const timer = setTimeout(() => {setCopyStatus(false)}, 2000);
            return () => clearTimeout(timer);
        }
        const value = '<div class="certification_image" data-color="'+props.version+'" data-id="'+props.organization+'"></div>\r\n<script src="https://code.jquery.com/jquery-3.1.1.min.js"></script>\r\n<script src="https://certification.naidonline.org/verify.js"></script>';
        return (
            <div className='certCode'>
                <textarea readOnly value={value} />
                <button className="btn" onClick={() => copyToClipboard(value)}>Copy To Clipboard</button>
                <span className='copyStatus' style={copyStyle}>Copied!</span>
            </div>
        )
    }
    function CertVerifierPreview(props:verifierProps){
        const bgStyle = props.version === 'white' ? "certVerifierPreview white" : "certVerifierPreview"
        return (
            <div className={bgStyle}>
                <h2>Preview</h2>
                <div className="certification_image" data-color={props.version} data-id={props.organization}></div>
            </div>
        )
    }

    if (display){
        return (
            <div className="main">
                <div className='row'>
                    <div className="col-full">
                        <h1>Certification Verifier</h1>
                        <p>The certification verifier code provided adds the appropriate certification icon to your website. It automatically embeds a custom link to a pop-up of your current Certification details that is always up-to-date.</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-half'>
                        <h2>Your Company's Code</h2>
                        <label className='versionSelect'>
                            Logo Version:
                            <select onChange={(event)=>handleVersionChange(event.target.value)}>
                                <option value="color">Color</option>
                                <option value="black">Black</option>
                                <option value="white">White</option>
                            </select>
                        </label>
                        <CertVerifierCode version={codeVersion} organization={appStore.orgID}/>
                    </div>
                    <div className='col-half'>
                        <CertVerifierPreview version={codeVersion} organization={appStore.orgID} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-full'>
                        <h2>Usage Instructions</h2>
                        <ol>
                            <li>Copy the code provided below.</li>
                            <li>Paste the code into a notepad file (Microsoft applications may add additional code that can break the verifier).</li>
                            <li>Save the notepad file.</li>
                            <li>Send the notepad file with the code to your IT department/web master.</li>
                            <li>Have the code copied into the location on your website where you would like the “NAID Certified - Click to Verify” icon to appear (e.g. your footer)</li>
                        </ol>

                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="main">

            </div>
        );
    }
}