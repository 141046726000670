import React, { useState } from 'react';
import { observer } from "mobx-react";
import { Next } from '../NavButtons'

interface Props {
    proceed:Function
    accept:string
}

export const FileUpload = observer((props:Props) => {

    const [selectedFile, setSelectedFile] = useState({} as File);
	const [isFilePicked, setIsFilePicked] = useState(false);

    const changeHandler = (event:any) => {
        if (event.target.files[0] !== null){
            setSelectedFile(event.target.files[0])
		    setIsFilePicked(true);
        }
		else {
            setSelectedFile({} as File)
            setIsFilePicked(false);
        }
	};

	const handleSubmission = () => {
        if (isFilePicked && selectedFile){
            props.proceed(selectedFile);
            setSelectedFile({} as File);
            setIsFilePicked(false);
        }
	};
    return (
        <div className='file-upload'>
            <label className='btn'>
                Choose File (PDF)
                <input type='file' accept={props.accept} onChange={(event)=>changeHandler(event)}/>
            </label>
            <span>Currently Selected: {(selectedFile !== undefined && selectedFile.name !== undefined ? selectedFile.name : 'No File Selected')}</span>
            {isFilePicked &&
                <div>
                    <Next onClick={handleSubmission} buttonText="Upload"/>
                </div>
            }
        </div>
    )
})

