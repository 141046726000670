import React, { useEffect, useState, useContext } from 'react';
import { observer } from "mobx-react";
import { checkAuthToken } from '../../api/API';
import { AppStoreContext, UserStoreContext } from "../../stores/AppStore";

import { Next, Prev } from '../NavButtons'

export const Step2 = observer(() => {

    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (appStore.checkToken){
            checkAuthToken(userStore.token,function(result:boolean){
                if (!result){
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
                else {
                    setDisplay(true);
                }
            })
        }
        else {
            setDisplay(true);
        }
    }, [])

    function nextPage(){
        appStore.checkToken = true;
        appStore.step++;
    }
    function prevPage(){
        appStore.checkToken = true;
        appStore.step--;
    }
    const infoRequestUpdate = (value:string) => {
        appStore.infoRequest = value;
    }

    if (display){
        return (
            <div className="step2">
                <h1>Part 2.) Destruction Location Information</h1>
                <div className='location-information'>
                    <p>Destruction Company Name (Applicant): <span>{appStore.orgName}</span></p>
                    <p>Destruction Physical Address:<br/><span>{appStore.orgPhysicalAddress}{(appStore.orgSuite !== "" ? " ("+appStore.orgSuite+")" : "")}<br/>{appStore.orgCity}, {appStore.orgState} {appStore.orgCountry} {appStore.orgMailCode}</span></p>
                    <p>Certification Contact: <span>{appStore.certContact}</span></p>
                    <p>Email: <span>{appStore.email}</span></p>
                    <p>Phone: <span>{appStore.phone}</span></p>
                    <p>Fax: <span>{appStore.fax}</span></p>
                </div>
                <p>Does any of the above information need updating? Describe the changes below and why.</p>
                <textarea
                className="full"
                value={appStore.infoRequest}
                onChange={(event) => infoRequestUpdate(event.target.value)} />

                <div className="nextPrevSection">
                    <div>
                        <Prev onClick={prevPage} buttonText="Back"/>
                    </div>
                    <div>
                        <Next onClick={nextPage} buttonText="Next"/>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="step2">

            </div>
        )
    }

})