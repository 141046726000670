import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { checkAuthToken } from '../api/API';
import { membersuiteURL, tenantId } from '../api/APICredentials';
import { UserStoreContext, AppStoreContext, Individual, Vehicle } from "../stores/AppStore";

export const Dashboard = observer(() => {

    const userStore = useContext(UserStoreContext);
    const appStore = useContext(AppStoreContext);

    const orgAction = (event: any, membersuiteID: string, page: string) => {
        event.preventDefault();
        appStore.orgID = membersuiteID;
        if (page === "AppForm") {
            appStore.loading = true;
            //Field Resets
            appStore.offerEEDS = 0;
            appStore.infoRequest = "";
            appStore.individuals = Array<Individual>()
            appStore.deletedIndividuals = Array<Individual>()
            appStore.checkIndividuals = false;
            appStore.vehicles = Array<Vehicle>();
            appStore.deletedVehicles = Array<Vehicle>();
            appStore.paperAdditionalFileName = ""
            appStore.paperAdditionalFile = {} as File
            appStore.agree = false;
            appStore.lineItems = Array();
            appStore.totalFees = 0;
            appStore.feeNames = "";
            appStore.creditCardNumber = ""
            appStore.expiryMonth = null
            appStore.expiryYear = null
            appStore.nameOnCard = ""
            appStore.securityCode = null
            //Get Current Application Information
            checkAuthToken(userStore.token, function (result: boolean) {
                if (result) {
                    //Get Organization Information
                    let orgurl = new URL(membersuiteURL + "/crm/v1/organizations/" + tenantId)
                    const organizationMSQL = "SELECT "
                        + "ConsultingSubsidy__c,"
                        + "Name,"
                        + "Physical_Address_Line1,"
                        + "unitSuite__c,"
                        + "Physical_Address_City,"
                        + "Physical_Address_State,"
                        + "Physical_Address_PostalCode,"
                        + "Physical_Address_Country,"
                        + "OfficeHours__c,"
                        + "HoursOperation__c,"
                        + "TimeFirstVehDisp__c,"
                        + "ConsistTime__c,"
                        + "VariesMonday__c,"
                        + "VariesTuesday__c,"
                        + "VariesWednesday__c,"
                        + "VariesThursday__c,"
                        + "VariesFriday__c,"
                        + "VariesSaturday__c,"
                        + "VariesSunday__c,"
                        + "SvcVehOtherAddress__c,"
                        + "SvcVehAddress__c,"
                        + "OppFacility__c,"
                        + "OppMobileOnsite__c,"
                        + "OppProcessingStation__c,"
                        + "OppOnlyFacility__c,"
                        + "AffDestrFacility__c,"
                        + "EndPPM__c,"
                        + "EndMM__c,"
                        + "EndPCHD__c,"
                        + "EndNPM__c,"
                        + "EndSSD__c,"
                        + "EndPD__c,"
                        + "F_1_3SubAbuseScreening__c,"
                        + "F_1_4AccIndividualScreening__c,"
                        + "F_1_4MostRecentSearch__c,"
                        + "F_1_1AETP__c,"
                        + "F_1_20TransferofCustody__c,"
                        + "F_1_20TransofCusOTHER__c,"
                        + "F_1_27DPOOfficer__c,"
                        + "F_1_27DPOemail__c,"
                        + "F_1_28ICCOOfficer__c,"
                        + "F_1_28iccoemailadd__c,"
                        + "F_4_1PPMFacility__c,"
                        + "F_4_1PPMMobileOnsite__c,"
                        + "F_4_1PPMContinuousShred__c,"
                        + "F_4_1PPMCrossCutPierce_Tear__c,"
                        + "F_4_1PPMDisintOrHammermill__c,"
                        + "F_4_1PPMUnspecifiedEquipment__c,"
                        + "F_4_1PPMEquipmentType__c,"
                        + "F_4_1PPMEquipOperation__c,"
                        + "F_4_1PPMEquipManufacturer__c,"
                        + "F_4_1PPMEquipModel__c,"
                        + "F_4_1PPMEquipSerial__c,"
                        + "F_4_1PPMEquipHorsepower__c,"
                        + "F_4_1PPMEquipUploaded__c,"
                        + "F_4_2MMFacility__c,"
                        + "F_4_2MMMobileOnsite__c,"
                        + "F_4_2MMEquipOperation__c,"
                        + "F_4_2MMEquipManufacturer__c,"
                        + "F_4_2MMEquipModel__c,"
                        + "F_4_3PCHDFacility__c,"
                        + "F_4_3PCHDMobileOnsite__c,"
                        + "F_4_3PCHDMethod__c,"
                        + "F_4_4SSDFacility__c,"
                        + "F_4_4SSDMobileOnsite__c,"
                        + "F_4_4SSDMethod__c,"
                        + "F_4_5NPMFacility__c,"
                        + "F_4_5NPMMobileOnsite__c,"
                        + "F_4_5NPMOptical__c,"
                        + "F_4_5NPMMagTape__c,"
                        + "F_4_5NPMOther__c,"
                        + "F_4_5NPMOtherSpecified__c,"
                        + "F_4_5NPMMethod__c,"
                        + "F_4_18PDFacility__c,"
                        + "F_4_18PDMobileOnsite__c "
                        + "FROM Organization WHERE id = '" + appStore.orgID + "'"

                    orgurl.searchParams.append("page", "1");
                    orgurl.searchParams.append("pageSize", "100");
                    orgurl.searchParams.append("msql", organizationMSQL);

                    const organizationHeaders = new Headers();
                    organizationHeaders.append("Authorization", userStore.token);

                    const organizationRequestOptions = {
                        method: 'GET',
                        headers: organizationHeaders
                    };
                    const orgJSON = fetch(orgurl.toString(), organizationRequestOptions)
                        .then(response => response.json())

                    //Get Certification Contact Info
                    let relurl = new URL(membersuiteURL + "/crm/v1/relationships/" + tenantId)
                    const relationshipsMSQL = "SELECT Type.Name,RightSide_Individual.Name,RightSide_Individual.EmailAddress,RightSide_Individual.Work_PhoneNumber,RightSide_Individual.Fax_PhoneNumber FROM Relationship WHERE LeftSide_Organization.ID = '{" + appStore.orgID + "}' AND Type.Name = 'Certification Contact'"
                    relurl.searchParams.append("page", "1");
                    relurl.searchParams.append("pageSize", "100");
                    relurl.searchParams.append("msql", relationshipsMSQL);

                    const relationshipsHeaders = new Headers();
                    relationshipsHeaders.append("Authorization", userStore.token);

                    const relationshipsRequestOptions = {
                        method: 'GET',
                        headers: relationshipsHeaders
                    };

                    const relJSON = fetch(relurl.toString(), relationshipsRequestOptions)
                        .then(response => response.json())

                    //Get Employee Information
                    let empurl = new URL(membersuiteURL + "/platform/v2/customobjects/" + tenantId)
                    const empQuery = "SELECT EmpName__c, DateofHire__c, Access__c, Title__c, Citizen__c "
                        + "FROM AccessInd__c WHERE Owner = '" + appStore.orgID + "'"

                    empurl.searchParams.append("page", "1");
                    empurl.searchParams.append("pageSize", "100");
                    empurl.searchParams.append("msql", empQuery);

                    const empHeaders = new Headers();
                    empHeaders.append("Authorization", userStore.token);

                    const employeeRequestOptions = {
                        method: 'GET',
                        headers: empHeaders
                    };
                    const empJSON = fetch(empurl.toString(), employeeRequestOptions)
                        .then(response => response.json())

                    //Get Vehicle Information
                    let vehurl = new URL(membersuiteURL + "/platform/v2/customobjects/" + tenantId)
                    const vehQuery = "SELECT TypeVeh__c, VinNo__c, MakeAndModel__c, LicPlateNo__c, StateIssued__c, OvernightAddress__c, AvailableForAudit__c "
                        + "FROM SvcVehList__c WHERE Owner = '" + appStore.orgID + "'"

                    vehurl.searchParams.append("page", "1");
                    vehurl.searchParams.append("pageSize", "100");
                    vehurl.searchParams.append("msql", vehQuery);

                    const vehHeaders = new Headers();
                    vehHeaders.append("Authorization", userStore.token);

                    const vehicleRequestOptions = {
                        method: 'GET',
                        headers: vehHeaders
                    };
                    const vehJSON = fetch(vehurl.toString(), vehicleRequestOptions)
                        .then(response => response.json())

                    Promise.all([orgJSON, relJSON, empJSON, vehJSON]).then((res) => {
                        const orgData = res[0][0];
                        const relData = res[1][0]
                        const empData = res[2];
                        const vehData = res[3];

                        console.log(orgData);

                        /* Step 1 */
                        if (orgData.consultingSubsidy__c !== null) {
                            appStore.consultingSubsidy = (orgData.consultingSubsidy__c ? 1 : 2)
                        }
                        else {
                            appStore.consultingSubsidy = 0
                        }

                        /* Step 2 */
                        appStore.orgName = (orgData.name ? orgData.name : "");
                        appStore.orgPhysicalAddress = (orgData.physical_Address_Line1 ? orgData.physical_Address_Line1 : "");
                        appStore.orgSuite = (orgData.unitSuite__c ? orgData.unitSuite__c : "");
                        appStore.orgCity = (orgData.physical_Address_City ? orgData.physical_Address_City : "");
                        appStore.orgState = (orgData.physical_Address_State ? orgData.physical_Address_State : "")
                        appStore.orgMailCode = (orgData.physical_Address_PostalCode ? orgData.physical_Address_PostalCode : "")
                        appStore.orgCountry = (orgData.physical_Address_Country ? orgData.physical_Address_Country : "")

                        if (typeof relData !== 'undefined') {
                            appStore.certContact = relData['rightSide_Individual.Name']
                            appStore.email = relData['rightSide_Individual.EmailAddress']
                            appStore.phone = relData['rightSide_Individual.Work_PhoneNumber']
                            appStore.fax = relData['rightSide_Individual.Fax_PhoneNumber']
                        }

                        /* Step 3 */
                        interface EmployeeObj {
                            id: string,
                            empName__c: string,
                            dateofHire__c: string,
                            access__c: boolean,
                            title__c: string,
                            citizen__c: boolean
                        }
                        let count = 0
                        empData.forEach((emp: EmployeeObj) => {

                            let dateString = ""
                            if (emp.dateofHire__c){
                                const dateint = Date.parse(emp.dateofHire__c)
                                const dateobj = new Date(dateint);
                                let month = dateobj.getMonth() + 1;
                                let day = dateobj.getDate();
                                let monthStr = ('0' + month).slice(-2)
                                let dayStr = ('0' + day).slice(-2)
                                dateString = dateobj.getFullYear() + "-" + monthStr + "-" + dayStr
                            }

                            appStore.individuals.push({
                                id: emp.id,
                                name: (emp.empName__c ? emp.empName__c : ""),
                                date: dateString,
                                access: (emp.access__c ? 1 : 2),
                                title: (emp.title__c ? emp.title__c : ""),
                                citizen: (emp.citizen__c ? 1 : 2)
                            } as Individual)

                            if (emp.access__c) {
                                count++;
                            }
                        })
                        appStore.numberAccessIndividuals = count;

                        //Vehicle__c
                        interface VehicleObj {
                            id: string,
                            typeVeh__c: string,
                            vinNo__c: string,
                            makeAndModel__c: string,
                            licPlateNo__c: string,
                            stateIssued__c: string,
                            overnightAddress__c: string,
                            availableForAudit__c: boolean
                        }
                        vehData.forEach((veh: VehicleObj) => {
                            appStore.vehicles.push({
                                id: veh.id,
                                type: (veh.typeVeh__c ? veh.typeVeh__c : ""),
                                vin: (veh.vinNo__c ? veh.vinNo__c : ""),
                                make: (veh.makeAndModel__c ? veh.makeAndModel__c : ""),
                                plate: (veh.licPlateNo__c ? veh.licPlateNo__c : ""),
                                state: (veh.stateIssued__c ? veh.stateIssued__c : ""),
                                overnight: (veh.overnightAddress__c ? veh.overnightAddress__c : ""),
                                audit: (veh.availableForAudit__c ? 1 : 2)
                            } as Vehicle)
                        })

                        appStore.officeHours = (orgData.officeHours__c ? orgData.officeHours__c : "")
                        appStore.operationHours = (orgData.hoursOperation__c ? orgData.hoursOperation__c : "")

                        appStore.firstDispatch = (orgData.timeFirstVehDisp__c ? orgData.timeFirstVehDisp__c : "")

                        appStore.firstDispatchTime = (orgData.consistTime__c ? orgData.consistTime__c : "")
                        appStore.firstDispatchTimeWk = {
                            Mo: (orgData.variesMonday__c ? orgData.variesMonday__c : ""),
                            Tu: (orgData.variesTuesday__c ? orgData.variesTuesday__c : ""),
                            We: (orgData.variesWednesday__c ? orgData.variesWednesday__c : ""),
                            Th: (orgData.variesThursday__c ? orgData.variesThursday__c : ""),
                            Fr: (orgData.variesFriday__c ? orgData.variesFriday__c : ""),
                            Sa: (orgData.variesSaturday__c ? orgData.variesSaturday__c : ""),
                            Su: (orgData.variesSunday__c ? orgData.variesSunday__c : "")
                        }

                        if (orgData.svcVehOtherAddress__c !== null) {
                            appStore.storedAtOtherAddress = (orgData.svcVehOtherAddress__c ? 1 : 2)
                        }
                        else {
                            appStore.storedAtOtherAddress = 0
                        }

                        appStore.storedAtOtherAddressInput = (orgData.svcVehAddress__c ? orgData.svcVehAddress__c : "")
                        appStore.programs.facilityBased = orgData.oppFacility__c;
                        appStore.programs.mobileOnsite = orgData.oppMobileOnsite__c;
                        appStore.programs.transferProc = orgData.oppProcessingStation__c;
                        appStore.programs.collectionOnly = orgData.oppOnlyFacility__c;
                        appStore.affiliatedFacilityAddress = (orgData.affDestrFacility__c ? orgData.affDestrFacility__c : "")
                        appStore.endorsements.paper = orgData.endPPM__c;
                        appStore.endorsements.microMedia = orgData.endMM__c;
                        appStore.endorsements.hardDrives = orgData.endPCHD__c;
                        appStore.endorsements.nonPaper = orgData.endNPM__c;
                        appStore.endorsements.solidState = orgData.endSSD__c;
                        appStore.endorsements.product = orgData.endPD__c;

                        /* Step 4 section 1*/
                        if (orgData.f_1_3SubAbuseScreening__c === "Option 1") {
                            appStore.substanceScreeningOption = 1
                        }
                        else if (orgData.f_1_3SubAbuseScreening__c === "Option 2") {
                            appStore.substanceScreeningOption = 2
                        }

                        if (orgData.f_1_4AccIndividualScreening__c === "Option 1") {
                            appStore.accessIndividualScreeningOption = 1
                        }
                        else if (orgData.f_1_4AccIndividualScreening__c === "Option 2") {
                            appStore.accessIndividualScreeningOption = 2
                        }
                        else if (orgData.f_1_4AccIndividualScreening__c === "Option 3") {
                            appStore.accessIndividualScreeningOption = 3
                            appStore.accessIndividualScreeningYear = (orgData.f_1_4MostRecentSearch__c ? orgData.f_1_4MostRecentSearch__c : "")
                        }

                        if (orgData.f_1_1AETP__c === "Option 1") {
                            appStore.accessIndividualTrainingOption = 1
                        }
                        else if (orgData.f_1_1AETP__c === "Option 2") {
                            appStore.accessIndividualTrainingOption = 2
                        }

                        if (orgData.f_1_20TransferofCustody__c === "Option 1") {
                            appStore.transferOfCustodyOption = 1
                        }
                        else if (orgData.f_1_20TransferofCustody__c === "Option 2") {
                            appStore.transferOfCustodyOption = 2
                        }
                        else if (orgData.f_1_20TransferofCustody__c === "Option 3") {
                            appStore.transferOfCustodyOption = 3
                        }
                        appStore.transferOfCustodyOther = (orgData.f_1_20TransofCusOTHER__c ? orgData.f_1_20TransofCusOTHER__c : "")
                        appStore.designatedDPO = (orgData.f_1_27DPOOfficer__c ? orgData.f_1_27DPOOfficer__c : "")
                        appStore.designatedDPOemail = (orgData.f_1_27DPOemail__c ? orgData.f_1_27DPOemail__c : "")
                        appStore.designatedICCO = (orgData.f_1_28ICCOOfficer__c ? orgData.f_1_28ICCOOfficer__c : "")

                        /*step 4 section 4*/
                        appStore.paperFacilityBased = orgData.f_4_1PPMFacility__c
                        appStore.paperMobileOnsite = orgData.f_4_1PPMMobileOnsite__c
                        appStore.paperContinuousShred = orgData.f_4_1PPMContinuousShred__c
                        appStore.paperCrossCut = orgData.f_4_1PPMCrossCutPierce_Tear__c
                        appStore.paperPulDisHamm = orgData.f_4_1PPMDisintOrHammermill__c
                        appStore.paperUnspecified = orgData.f_4_1PPMUnspecifiedEquipment__c
                        appStore.paperUnspecifiedField = (orgData.f_4_1PPMEquipmentType__c ? orgData.f_4_1PPMEquipmentType__c : "")
                        appStore.paperOnsiteOrFacility = (orgData.f_4_1PPMEquipOperation__c ? orgData.f_4_1PPMEquipOperation__c : "")
                        appStore.paperManufacturer = (orgData.f_4_1PPMEquipManufacturer__c ? orgData.f_4_1PPMEquipManufacturer__c : "")
                        appStore.paperModel = (orgData.f_4_1PPMEquipModel__c ? orgData.f_4_1PPMEquipModel__c : "")
                        appStore.paperSerial = (orgData.f_4_1PPMEquipSerial__c ? orgData.f_4_1PPMEquipSerial__c : "")
                        appStore.paperHorsepower = (orgData.f_4_1PPMEquipHorsepower__c ? orgData.f_4_1PPMEquipHorsepower__c : "")
                        //appStore.paperAdditionalEquipment = orgData.f_4_1PPMEquipUploaded__c //Upload isn't saved, so this has to reset
                        appStore.microFacilityBased = orgData.f_4_2MMFacility__c
                        appStore.microMobileOnsite = orgData.f_4_2MMMobileOnsite__c
                        appStore.microOnsiteOrFacility = (orgData.f_4_2MMEquipOperation__c ? orgData.f_4_2MMEquipOperation__c : "")
                        appStore.microManufacturer = (orgData.f_4_2MMEquipManufacturer__c ? orgData.f_4_2MMEquipManufacturer__c : "")
                        appStore.microModel = (orgData.f_4_2MMEquipModel__c ? orgData.f_4_2MMEquipModel__c : "")
                        appStore.hardDriveFacilityBased = orgData.f_4_3PCHDFacility__c
                        appStore.hardDriveMobileOnsite = orgData.f_4_3PCHDMobileOnsite__c
                        appStore.hardDriveMethod = (orgData.f_4_3PCHDMethod__c ? orgData.f_4_3PCHDMethod__c : "")
                        appStore.solidStateFacilityBased = orgData.f_4_4SSDFacility__c
                        appStore.solidStateMobileOnsite = orgData.f_4_4SSDMobileOnsite__c
                        appStore.solidStateMethod = (orgData.f_4_4SSDMethod__c ? orgData.f_4_4SSDMethod__c : "")
                        appStore.nonPaperFacilityBased = orgData.f_4_5NPMFacility__c
                        appStore.nonPaperMobileOnsite = orgData.f_4_5NPMMobileOnsite__c
                        appStore.nonPaperOpticalMedia = orgData.f_4_5NPMOptical__c
                        appStore.nonPaperMagneticTape = orgData.f_4_5NPMMagTape__c
                        appStore.nonPaperOther = orgData.f_4_5NPMOther__c
                        appStore.nonPaperOtherField = (orgData.f_4_5NPMOtherSpecified__c ? orgData.f_4_5NPMOtherSpecified__c : "")
                        appStore.nonPaperMethod = (orgData.f_4_5NPMMethod__c ? orgData.f_4_5NPMMethod__c : "")
                        appStore.productFacilityBased = orgData.f_4_18PDFacility__c
                        appStore.productMobileOnsite = orgData.f_4_18PDMobileOnsite__c

                        appStore.checkToken = false;
                        appStore.page = "AppForm";
                        appStore.step = 1;
                        appStore.loading = false;
                    }).catch(function (err) {
                        console.log(err.message);
                    });
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            });
        }
        else {
            appStore.page = page;
        }
    }

    /*const sendPassResetEmail = (event:any) =>{
        event.preventDefault();
    }*/

    interface User {
        firstName: string,
        lastName: string,
        ownerId: string,
        email: string
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        appStore.orgID = ""
        appStore.step = 0
        appStore.substep = ""
        appStore.errors = Array<string>()

        checkAuthToken(userStore.token, function (result: boolean, user: User) {
            if (result) {
                userStore.displayName = user.firstName + " " + user.lastName
                userStore.user = user.ownerId
                userStore.email = user.email

                //Determine which Organizations to display by checking the Relationships of the current user
                let relurl = new URL(membersuiteURL + "/crm/v1/relationships/" + tenantId)
                const relationshipsMSQL = "SELECT Type.Name,RightSide_Individual.LocalID,LeftSide_Organization.ID FROM Relationship WHERE RightSide_Individual = '{" + userStore.user + "}' AND isActive = true"
                relurl.searchParams.append("page", "1");
                relurl.searchParams.append("pageSize", "100");
                relurl.searchParams.append("msql", relationshipsMSQL);

                const relationshipsHeaders = new Headers();
                relationshipsHeaders.append("Authorization", userStore.token);

                const relationshipsRequestOptions = {
                    method: 'GET',
                    headers: relationshipsHeaders
                };

                fetch(relurl.toString(), relationshipsRequestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        if (result.length > 0) {
                            interface Relationship {
                                "type.Name": string,
                                "leftSide_Organization.ID": string
                            }
                            const roles = ['Advertising Contact 3',
                            'Branch (Max-Pay)',
                            'Branch Office',
                            'Certification Contact',
                            'Communication Contact',
                            'Corporate Office',
                            'DPO',
                            'Employee',
                            'Financial Contact',
                            'ICCO',
                            'Organization Contact',
                            'Primary Contact',
                            'Privacy + Contact',
                            'Website Contact'];
                            var whereClause = ""
                            result.map((relationship: Relationship) => {
                                if (roles.includes(relationship["type.Name"])) {
                                    if (whereClause !== "") {
                                        whereClause += " OR "
                                    }
                                    whereClause += "id = '" + relationship['leftSide_Organization.ID'] + "'"
                                }
                            })
                            if (whereClause !== "") {
                                let orgurl = new URL(membersuiteURL + "/crm/v1/organizations/" + tenantId)
                                const organizationMSQL = "SELECT Name,Physical_Address_Line1,Physical_Address_City,Physical_Address_State,Physical_Address_PostalCode,Physical_Address_Country,YsnCertified__c FROM Organization WHERE " + whereClause
                                orgurl.searchParams.append("page", "1");
                                orgurl.searchParams.append("pageSize", "100");
                                orgurl.searchParams.append("msql", organizationMSQL);

                                const organizationHeaders = new Headers();
                                organizationHeaders.append("Authorization", userStore.token);

                                const organizationRequestOptions = {
                                    method: 'GET',
                                    headers: organizationHeaders
                                };
                                fetch(orgurl.toString(), organizationRequestOptions)
                                    .then(response => response.json())
                                    .then((result) => {
                                        userStore.organizations = result
                                        appStore.loading = false;
                                    }).catch(error => {
                                        console.log(error)
                                        alert('E105: Could Not Fetch Record. Try again later.')
                                        window.location.replace(`${process.env.PUBLIC_URL}`);
                                    });
                            }
                            else {
                                appStore.loading = false;
                            }
                        }
                        else {
                            appStore.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        alert('E104: Could Not Fetch Record. Try again later.')
                        window.location.replace(`${process.env.PUBLIC_URL}`);
                    });
            }
            else {
                window.location.replace(`${process.env.PUBLIC_URL}`);
            }
        })
    }, [])

    return (
        <div className="main">
            <img src={`${process.env.PUBLIC_URL}/images/loader-lg.gif`} alt="Loading" className='large-loader' style={!appStore.loading ? {display: 'none'} : {} }/>
            {!appStore.loading &&
                <div>
                    <div className='row'>
                        <div className='col-full'>
                            <h1>Certification Portal Profile</h1>
                            <p>Hello, {userStore.displayName}!</p>
                            <p><em>Note: You may renew your Certification using the online Application button below; however, this is currently only available for Physical Media Destruction. For Electronic Media Erasure and PSPF Endorsements, please use the <a href="https://isigmaonline.org/certifications/apply-renew/">manual applications found here.</a></em></p>
                            <p><em>Not seeing all of your member locations? Please contact Joye Rea at jrea@isigmaonline.org for assistance.</em></p>
                            <h2>Organizations</h2>
                            {userStore.organizations.length > 0 &&
                                <div className='organizationList'>
                                    {userStore.organizations.map(function (org) {
                                        return (
                                            <div className="organization" key={org.id}>
                                                <div>
                                                    <h3>{org.name}</h3>
                                                    <p>{org.physical_Address_Line1}<br />
                                                        {org.physical_Address_City}, {org.physical_Address_State} {org.physical_Address_PostalCode}</p>
                                                </div>
                                                {org.ysnCertified__c &&
                                                    <div className='btnGroup'>
                                                        <button className='btn admin-btn' onClick={(event) => orgAction(event, org.id, 'AppForm')}>Renew Application</button>
                                                        <button className='btn admin-btn' onClick={(event) => orgAction(event, org.id, 'CertVerify')}>Certification Verifier</button>
                                                    </div>
                                                }
                                                {!org.ysnCertified__c &&
                                                    <p>First-time Certification Applicants should return completed <a href="https://isigmaonline.org/certifications/apply-renew/" target='_blank' rel='noreferrer noopener'>PDF applications</a> to certification@isigmaonline.org.</p>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {userStore.organizations.length === 0 &&
                                <div>
                                    <p>You do not have the correct permissions to make application requests for any organizations. Please contact certification@isigmaonline.org for further assistance.</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        {/*<div className='col-half'>
                            <h2>Update Password</h2>
                            <div className='btnGroup wrap'>
                                <button className="btn admin-btn" onClick={(event)=>sendPassResetEmail(event)}>Send Password Reset Email</button>
                            </div>
                        </div>*/}
                        <div className='col-half'>
                            <h2>Resources</h2>
                            <div className='btnGroup wrap'>
                                <a className="btn secondary admin-btn" href="https://isigmaonline.box.com/s/jxbv6nnce815gxax6hfam878ji15mc5t">Program Resources</a>
                                <a className="btn secondary admin-btn" href="https://isigmaonline.box.com/s/4m1qz9b3hu4xdr8tzduohzvshfpfedoj">Marketing Tools</a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
})