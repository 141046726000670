import React, { useContext } from 'react';

import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext } from "./stores/AppStore";

import Header from './components/Header'
import { Footer } from './components/Footer'


import { Login } from './components/Login'
import { Dashboard } from './components/Dashboard'
import { CertVerify } from './components/CertVerify'
import { AppForm } from './components/appform/AppForm'

export const App = observer(() => {
  const userStore = useContext(UserStoreContext);
  const appStore = useContext(AppStoreContext);

  return (
    <div className="App">
      <Header />
      <main>
        {userStore.token === '' &&
          <Login />
        }
        {userStore.token !== '' && appStore.page === "Dashboard" &&
          <Dashboard />
        }
        {userStore.token !== '' && appStore.page === "AppForm" &&
          <AppForm />
        }
        {userStore.token !== '' && appStore.page === "CertVerify" &&
          <CertVerify />
        }
      </main>
      <Footer />
    </div>
  );

})