import React, { useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext } from "../../stores/AppStore";

import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { Step6 } from './Step6'

export const AppForm = observer(() => {

    const appStore = useContext(AppStoreContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <div className="main">
            {appStore.step === 1 &&
                <Step1 />
            }
            {appStore.step === 2 &&
                <Step2 />
            }
            {appStore.step === 3 &&
                <Step3 />
            }
            {appStore.step === 4 &&
                <Step4 />
            }
            {appStore.step === 5 &&
                <Step5 />
            }
            {appStore.step === 6 &&
                <Step6 />
            }
        </div>
    )
})