import React, {useContext} from 'react';
import { observer } from "mobx-react";
import { AppStoreContext } from "../stores/AppStore";

interface Props {
    onClick: any
    buttonText: string
}

export const Next = observer(({ onClick, buttonText }: Props) => {
    const appStore = useContext(AppStoreContext);
    return (
        <button className={appStore.loading ? "btn next loading" : "btn next"} disabled={appStore.loading} onClick={onClick}>
            <span>{buttonText}</span>
            <img width="60" height="23" src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="loading" style={!appStore.loading ? {display: 'none'} : {}} />
        </button>
    )
})

export const AdminNext = observer(({ onClick, buttonText }: Props) => {
    const appStore = useContext(AppStoreContext);
    return (
        <button className={appStore.loading ? "btn admin-btn next loading" : "btn admin-btn next"} disabled={appStore.loading} onClick={onClick}>
            <span>{buttonText}</span>
            <img width="30" src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="loading" style={!appStore.loading ? {display: 'none'} : {}} />
        </button>
    )
})

export const Prev = ({ onClick, buttonText }: Props) => {
    return (
        <button className="btn back" onClick={onClick}>
            <span>{buttonText}</span>
        </button>
    )
}
