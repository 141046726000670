import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { AppStoreContext, UserStoreContext, Individual, Vehicle, fDTimeWeek } from "../../stores/AppStore";
import { ErrorDisplay } from '../ErrorDisplay'
import { checkAuthToken, updateOrganization, saveAndUpdateVehicles, saveAndUpdateIndividuals} from '../../api/API';

import { Next, Prev } from '../NavButtons'

export const Step3 = observer(() => {

    const appStore = useContext(AppStoreContext);
    const userStore = useContext(UserStoreContext);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (appStore.checkToken){
            checkAuthToken(userStore.token,function(result:boolean){
                if (!result){
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
                else {
                    setDisplay(true);
                }
            })
        }
        else {
            setDisplay(true);
        }
    }, [])

    function prevPage(){
        appStore.errors = [];
        appStore.checkToken = true;
        appStore.step--;
    }

    function checkDispatch(current:string, dispatch:string, value:string){
        if (dispatch===current){
            return value;
        }
        else {
            return "";
        }
    }

    function nextPage(){
        appStore.errors = [];
        if (appStore.individuals.length === 0){
            appStore.errors.push('Error: Please add to the list of individuals')
        }
        /*else if (!appStore.checkIndividuals){
            appStore.errors.push('Please confirm that the individual list is still accurate')
        }*/
        if (appStore.officeHours === ""){
            appStore.errors.push('Error: Please provide office hours')
        }
        console.log(typeof appStore.firstDispatchTime);
        if (appStore.firstDispatch === "Consistent Daily" && appStore.firstDispatchTime === ""){
            appStore.errors.push('Error: Please completely fill in the time of first dispatch')
        }
        else if (appStore.firstDispatch === "Varies Daily"){
            let invalid = false;
            let keys = Object.keys(appStore.firstDispatchTimeWk)
            for (let i=7;i<keys.length;i++){
                if (appStore.firstDispatchTimeWk[keys[i]]===""){
                    invalid = true;
                    break;
                }
            }
            if (invalid){
                appStore.errors.push('Error: Please fill in the time for first Service Vehicle Dispatch')
            }
        }

        //At least one program must be selected
        if (!appStore.programs.facilityBased && !appStore.programs.mobileOnsite && !appStore.programs.transferProc && !appStore.programs.collectionOnly){
            appStore.errors.push('Error: Please select at least one platform by which media is processed at this location')
        }
        //If they have selected collection only, facility based must also be selected
        else if (appStore.programs.collectionOnly && !appStore.programs.facilityBased ){
            appStore.errors.push('Error: Applicants that select Collection-Only Facility under platforms must also select Facility-based Destruction')
        }
        //If collection-only or transfer processing station are selected, list addressed of affiliated facilities must be filled in
        if (appStore.programs.transferProc || appStore.programs.collectionOnly){
            if (appStore.affiliatedFacilityAddress === ""){
                appStore.errors.push('Error: Please list addresses of affiliated destruction facilities')
            }
        }
        //If any of these three programs are selected, then at least one endorsement must be selected
        if (appStore.programs.facilityBased || appStore.programs.mobileOnsite || appStore.programs.collectionOnly ){
            if (!appStore.endorsements.paper && !appStore.endorsements.hardDrives && !appStore.endorsements.microMedia && !appStore.endorsements.solidState && !appStore.endorsements.nonPaper && !appStore.endorsements.product){
                appStore.errors.push('Error: Please select at least one endorsement that applies to this location')
            }
        }

        if (appStore.errors.length===0){
            appStore.loading=true;
            checkAuthToken(userStore.token, (result:boolean)=>{
                if (result){
                    const fields = {
                        "options": [{
                            "Name": "OfficeHours__c",
                            "Value": appStore.officeHours
                        },
                        {
                            "Name":"HoursOperation__c",
                            "Value": appStore.operationHours
                        },
                        {
                            "Name":"TimeFirstVehDisp__c",
                            "Value": appStore.firstDispatch
                        },
                        {
                            "Name":"ConsistTime__c",
                            "Value": checkDispatch('Consistent Daily',appStore.firstDispatch,appStore.firstDispatchTime)
                        },
                        {
                            "Name":"VariesMonday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Mo)
                        },
                        {
                            "Name":"VariesTuesday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Tu)
                        },
                        {
                            "Name":"VariesWednesday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.We)
                        },
                        {
                            "Name":"VariesThursday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Th)
                        },
                        {
                            "Name":"VariesFriday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Fr)
                        },
                        {
                            "Name":"VariesSaturday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Sa)
                        },
                        {
                            "Name":"VariesSunday__c",
                            "Value": checkDispatch('Varies Daily',appStore.firstDispatch,appStore.firstDispatchTimeWk.Su)
                        },
                        {
                            "Name":"SvcVehOtherAddress__c",
                            "Value": (appStore.storedAtOtherAddress === 1 ? "True" : "False")
                        },
                        {
                            "Name":"SvcVehAddress__c",
                            "Value": (appStore.storedAtOtherAddress === 1 ? appStore.storedAtOtherAddressInput : "")
                        },
                        {
                            "Name":"OppFacility__c",
                            "Value": (appStore.programs.facilityBased ? "True" : "False")
                        },
                        {
                            "Name":"OppMobileOnsite__c",
                            "Value": (appStore.programs.mobileOnsite ? "True" : "False")
                        },
                        {
                            "Name":"OppProcessingStation__c",
                            "Value": (appStore.programs.transferProc ? "True" : "False")
                        },
                        {
                            "Name":"OppOnlyFacility__c",
                            "Value": (appStore.programs.collectionOnly ? "True" : "False")
                        },
                        {
                            "Name":"AffDestrFacility__c",
                            "Value": appStore.affiliatedFacilityAddress
                        },
                        {
                            "Name":"EndPPM__c",
                            "Value": (appStore.endorsements.paper ? "True" : "False")
                        },
                        {
                            "Name":"EndMM__c",
                            "Value": (appStore.endorsements.microMedia ? "True" : "False")
                        },
                        {
                            "Name":"EndPCHD__c",
                            "Value": (appStore.endorsements.hardDrives ? "True" : "False")
                        },
                        {
                            "Name":"EndNPM__c",
                            "Value": (appStore.endorsements.nonPaper ? "True" : "False")
                        },
                        {
                            "Name":"EndSSD__c",
                            "Value": (appStore.endorsements.solidState ? "True" : "False")
                        },
                        {
                            "Name":"EndPD__c",
                            "Value": (appStore.endorsements.product ? "True" : "False")
                        }
                    ]}
                    updateOrganization( userStore.token, appStore.orgID, fields, (response:any)=>{
                        appStore.loading=false;
                        appStore.checkToken=false;
                        appStore.step++;
                        appStore.substep = "section1";
                    })
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            });
        }
        else {
            window.scrollTo(0, 0)
        }
    }
    function step3SubSection(substep:string){
        appStore.errors=[];
        setDisplay(false);
        /*if (substep === "individual-list"){
            appStore.checkIndividuals = true;
        }*/
        checkAuthToken(userStore.token, (result:boolean)=>{
            if (result){
                appStore.substep=substep;
                setDisplay(true);
            }
            else {
                window.location.replace(`${process.env.PUBLIC_URL}`);
            }
        })

    }
    function validateIndividualList(){
        appStore.errors = [];
        let invalid = false;
        for (const entry of Object.entries(appStore.individuals)){
            if (!entry[1].name||!entry[1].date||!entry[1].title||entry[1].access===0||entry[1].citizen===0){
                invalid = true;
                break;
            }
        }
        if (invalid){
            appStore.errors.push("Error: Fill out all details about each individual")
            window.scrollTo(0, 0)
        }
        else {
            appStore.loading = true
            checkAuthToken(userStore.token,(result:boolean)=>{
                if (result){
                    interface EmployeeObj {
                        id:string,
                        empName__c:string,
                        dateofHire__c:string,
                        access__c:boolean,
                        title__c:string,
                        citizen__c:boolean
                    }
                    saveAndUpdateIndividuals(userStore.token, appStore.orgID, appStore.individuals, appStore.deletedIndividuals, appStore.numberAccessIndividuals, (response:Array<EmployeeObj>)=>{
                        let count = 0
                        appStore.individuals = [];
                        appStore.deletedIndividuals = [];
                        response.forEach((emp:EmployeeObj)=>{
                            const dateint = Date.parse(emp.dateofHire__c)
                            const dateobj = new Date(dateint);
                            let month = dateobj.getMonth() + 1;
                            let day = dateobj.getDate();
                            let monthStr = ('0' + month).slice(-2)
                            let dayStr = ('0' + day).slice(-2)

                            appStore.individuals.push({
                                id:emp.id,
                                name:emp.empName__c,
                                date: dateobj.getFullYear()+"-"+monthStr+"-"+dayStr ,
                                access:(emp.access__c ? 1 : 2),
                                title:emp.title__c,
                                citizen:(emp.citizen__c ? 1 : 2)
                            } as Individual)

                            if(emp.access__c){
                                count++;
                            }
                        })
                        appStore.numberAccessIndividuals = count;

                        appStore.loading=false;
                        appStore.checkToken=false;
                        appStore.substep="";
                        window.scrollTo(0, 0)
                    })
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            })
        }
    }
    function validateVehicleList(){
        appStore.errors = [];
        let invalid = false;
        for (const entry of Object.entries(appStore.vehicles)){
            if (!entry[1].type||!entry[1].vin||!entry[1].make||!entry[1].plate||!entry[1].state||entry[1].audit===0){
                invalid = true;
                break;
            }
        }
        if (invalid){
            appStore.errors.push("Error: Ensure the type, VIN #, make and model, license plate, state / province of license and audit availability are filled out for each vehicle")
            window.scrollTo(0, 0)
        }
        else {
            appStore.loading = true
            checkAuthToken(userStore.token,(result:boolean)=>{
                if (result){
                    interface VehicleObj {
                        id:string,
                        typeVeh__c:string,
                        vinNo__c:string,
                        makeAndModel__c:string,
                        licPlateNo__c:string,
                        stateIssued__c:string,
                        overnightAddress__c:string,
                        availableForAudit__c:boolean
                    }
                    saveAndUpdateVehicles(userStore.token, appStore.orgID, appStore.vehicles, appStore.deletedVehicles, (response:Array<VehicleObj>)=>{
                        appStore.vehicles = [];
                        appStore.deletedVehicles = [];
                        response.forEach((veh:VehicleObj)=>{
                            appStore.vehicles.push({
                                id:veh.id,
                                type:veh.typeVeh__c,
                                vin:veh.vinNo__c,
                                make:veh.makeAndModel__c,
                                plate:veh.licPlateNo__c,
                                state:veh.stateIssued__c,
                                overnight:veh.overnightAddress__c,
                                audit:(veh.availableForAudit__c ? 1 : 2)
                            } as Vehicle)
                        })

                        appStore.loading=false;
                        appStore.checkToken=false;
                        appStore.substep="";
                        window.scrollTo(0, 0)
                    })
                }
                else {
                    window.location.replace(`${process.env.PUBLIC_URL}`);
                }
            })
        }
    }
    const officeHoursUpdate = (value:string) => {
        appStore.officeHours = value;
    }
    const operationHoursUpdate = (value:string) => {
        appStore.operationHours = value;
    }
    const firstDispatchUpdate = (value:string) => {
        if (value === appStore.firstDispatch){
            appStore.firstDispatch = "";
        }
        else {
            appStore.firstDispatch = value;
        }
    }
    const firstDispatchTimeUpdate = (value:string) => {
        appStore.firstDispatchTime = value;
    }
    const storedAtOtherAddressUpdate = (value:number) => {
        if (value === appStore.storedAtOtherAddress){
            appStore.storedAtOtherAddress = 0;
        }
        else {
            appStore.storedAtOtherAddress = value;
        }
    }
    const storedAtOtherAddressInputUpdate = (value:string) => {
        appStore.storedAtOtherAddressInput = value;
    }
    const programsUpdate = (value:string) => {
        if (value==="facilityBased"){
            appStore.programs.facilityBased = (appStore.programs.facilityBased ? false : true)
        }
        else if (value==="mobileOnsite"){
            appStore.programs.mobileOnsite = (appStore.programs.mobileOnsite ? false : true)
        }
        else if (value==="transferProc"){
            appStore.programs.transferProc = (appStore.programs.transferProc ? false : true)
        }
        else if (value==="collectionOnly"){
            appStore.programs.collectionOnly = (appStore.programs.collectionOnly ? false : true)
        }
    }
    const affiliatedFacilityAddressUpdate = (value:string) => {
        appStore.affiliatedFacilityAddress = value;
    }
    const endorsementsUpdate = (value:string) => {
        if (value==="paper"){
            appStore.endorsements.paper = (appStore.endorsements.paper ? false : true)
        }
        else if (value==="hardDrives"){
            appStore.endorsements.hardDrives = (appStore.endorsements.hardDrives ? false : true)
        }
        else if (value==="microMedia"){
            appStore.endorsements.microMedia = (appStore.endorsements.microMedia ? false : true)
        }
        else if (value==="solidState"){
            appStore.endorsements.solidState = (appStore.endorsements.solidState ? false : true)
        }
        else if (value==="nonPaper"){
            appStore.endorsements.nonPaper = (appStore.endorsements.nonPaper ? false : true)
        }
        else if (value==="product"){
            appStore.endorsements.product = (appStore.endorsements.product ? false : true)
        }
    }
    interface AccessIndividualProps {
        index: number
        object: Individual
    }
    const AccessIndividual = observer((props:AccessIndividualProps) => {
        const calcNumberOfAccess = ()=>{
            let count = 0
            for (const entry of Object.entries(appStore.individuals)){
                if(entry[1].access){
                    count++;
                }
            }
            appStore.numberAccessIndividuals = count;
        }
        const individualNameUpdate = (value:string) => {
            props.object.name = value;
        }
        const individualDateUpdate = (value:string) => {
            props.object.date = value;
        }
        const individualAccessUpdate = (value:number) => {
            props.object.access = value
            calcNumberOfAccess()
        }
        const individualTitleUpdate = (value:string) => {
            props.object.title = value;
        }
        const individualCitizenUpdate = (value:number) => {
            props.object.citizen = value
        }
        const removeIndividual = () => {
            if (!props.object.id.startsWith('temp-')){
                appStore.deletedIndividuals.push(appStore.individuals[props.index]);
            }
            appStore.individuals.splice(props.index,1);
            calcNumberOfAccess()
        }
        return (
            <div className='individual'>
                <label>Name:<br/>
                    <input
                        type="text"
                        value={props.object.name}
                        onChange={(event) => individualNameUpdate(event.target.value)} />
                </label>
                <label>Date of Hire:<br/>
                    <input
                        type="date"
                        value={props.object.date}
                        onChange={(event) => individualDateUpdate(event.target.value)} />
                </label>
                <div>Access?
                    <label className="fancyCheckbox" onClick={() => individualAccessUpdate(1)}>
                        <span className={props.object.access === 1 ? "checked" : ""}></span>
                        Yes
                    </label>
                    <label className="fancyCheckbox" onClick={() => individualAccessUpdate(2)}>
                        <span className={props.object.access === 2 ? "checked" : ""}></span>
                        No
                    </label>
                </div>
                <label>Title / Position:<br/>
                    <input
                        type="text"
                        value={props.object.title}
                        onChange={(event) => individualTitleUpdate(event.target.value)} />
                </label>
                <div>Citizen?
                    <label className="fancyCheckbox" onClick={() => individualCitizenUpdate(1)}>
                        <span className={props.object.citizen === 1 ? "checked" : ""}></span>
                        Yes
                    </label>
                    <label className="fancyCheckbox" onClick={() => individualCitizenUpdate(2)}>
                        <span className={props.object.citizen === 2 ? "checked" : ""}></span>
                        No
                    </label>
                </div>
                <div className="btnwrapper">
                    <button className="btn admin-btn" onClick={()=> removeIndividual()}>Remove</button>
                </div>
            </div>
        )
    })
    interface ServiceVehicleProps {
        index: number
        object: Vehicle
    }
    const ServiceVehicle = observer((props:ServiceVehicleProps)=>{
        const vehicleTypeUpdate = (value:string) => {
            props.object.type = value;
        }
        const vehicleVinUpdate = (value:string) => {
            props.object.vin = value;
        }
        const vehicleMakeUpdate = (value:string) => {
            props.object.make = value;
        }
        const vehiclePlateUpdate = (value:string) => {
            props.object.plate = value;
        }
        const vehicleStateUpdate = (value:string) => {
            props.object.state = value;
        }
        const vehicleOvernightUpdate = (value:string) => {
            props.object.overnight = value;
        }
        const vehicleAuditUpdate = (value:number) => {
            props.object.audit = value
        }
        const removeVehicle = () => {
            if (!props.object.id.startsWith('temp-')){
                appStore.deletedVehicles.push(appStore.vehicles[props.index]);
            }
            appStore.vehicles.splice(props.index,1);
        }
        return (
            <div className='vehicle'>
                <div>Type
                    <label className="fancyCheckbox" onClick={() => vehicleTypeUpdate("Destruction")}>
                        <span className={props.object.type === "Destruction" ? "checked" : ""}></span>
                        Destruction
                    </label>
                    <label className="fancyCheckbox" onClick={() => vehicleTypeUpdate("Collection")}>
                        <span className={props.object.type === "Collection" ? "checked" : ""}></span>
                        Collection
                    </label>
                </div>
                <div className='fields-section'>
                    <label>Vehicle Identification Number (VIN #):<br/>
                        <input
                            type="text"
                            value={props.object.vin}
                            onChange={(event) => vehicleVinUpdate(event.target.value)} />
                    </label>
                    <label>Vehicle Make &amp; Model:<br/>
                        <input
                            type="text"
                            value={props.object.make}
                            onChange={(event) => vehicleMakeUpdate(event.target.value)} />
                    </label>
                    <label>License Plate Number:<br/>
                        <input
                            type="text"
                            value={props.object.plate}
                            onChange={(event) => vehiclePlateUpdate(event.target.value)} />
                    </label>
                    <label>State / Province of License:<br/>
                        <input
                            type="text"
                            value={props.object.state}
                            onChange={(event) => vehicleStateUpdate(event.target.value)} />
                    </label>
                    <label>Overnight Storage Address<br/>(Addr, City, State)<br/>
                        <input
                            type="text"
                            value={props.object.overnight}
                            onChange={(event) => vehicleOvernightUpdate(event.target.value)} />
                    </label>
                    <div>Available for Audit?
                        <label className="fancyCheckbox" onClick={() => vehicleAuditUpdate(1)}>
                            <span className={props.object.audit === 1 ? "checked" : ""}></span>
                            Yes
                        </label>
                        <label className="fancyCheckbox" onClick={() => vehicleAuditUpdate(2)}>
                            <span className={props.object.audit === 2 ? "checked" : ""}></span>
                            No
                        </label>
                    </div>
                </div>
                <div className="btnwrapper">
                    <button className="btn admin-btn" onClick={()=> removeVehicle()}>Remove</button>
                </div>
            </div>
        )
    })
    interface DispatchTimeFieldProps {
        day: string
        value: string
    }
    const DispatchTimeWkField = (props:DispatchTimeFieldProps) => {
        const firstDispatchTimeWkUpdate = (value:string) => {
            appStore.firstDispatchTimeWk[props.day] = value;
        }
        const fullDays:fDTimeWeek = {
            Mo:"Monday",
            Tu:"Tuesday",
            We:"Wednesday",
            Th:"Thursday",
            Fr:"Friday",
            Sa:"Saturday",
            Su:"Sunday"
        }
        return (
            <label>{fullDays[props.day]}<br/>
            <input
                type="time"
                id={"fdt" + props.day}
                className="firstDispatchTime"
                value={props.value}
                onChange={(event) => firstDispatchTimeWkUpdate(event.target.value)} />
            </label>
        )
    }

    if (!display){
        return (
            <div>

            </div>
        )
    }
    else if (appStore.substep === "individual-list") {
        return (
            <div className="individual-list">
                <h1>Part 3.) Operational Information</h1>
                <ErrorDisplay />
                <h2>Access and Non-Access Individuals List</h2>
                {appStore.individuals.map((object,index)=><AccessIndividual key={object.id} index={index} object={object}/>)}

                <button className='btn' style={{'marginTop':'15px'}} onClick={()=>appStore.individuals.push({id:"temp-"+appStore.individuals.length + 1,name:"",date:"",access:0,title:"",citizen:0} as Individual)}>Add Individual</button>

                <div className="nextPrevSection">
                    <div>
                    </div>
                    <div>
                        <Next onClick={()=>validateIndividualList()} buttonText="Finished"/>
                    </div>
                </div>
            </div>
        )
    }
    else if (appStore.substep === "vehicle-list"){
        return (
            <div className="vehicle-list">
            <h1>Part 3.) Operational Information</h1>
            <ErrorDisplay />
            <h2>Service Vehicle List</h2>
            {appStore.vehicles.map((object,index)=><ServiceVehicle key={object.id} index={index} object={object}/>)}

            <button className='btn' style={{'marginTop':'15px'}} onClick={()=>appStore.vehicles.push({id:"temp-"+appStore.vehicles.length + 1,type:"",vin:"",make:"",plate:"",state:"",overnight:"",audit:0} as Vehicle)}>Add Vehicle</button>

            <div className="nextPrevSection">
                <div>
                </div>
                <div>
                    <Next onClick={()=>validateVehicleList()} buttonText="Finished"/>
                </div>
            </div>
        </div>
        )
    }
    else {
        return (
            <div className="step3">
                <h1>Part 3.) Operational Information</h1>
                <ErrorDisplay />

                <p>Number of Access Individuals (Employees / Agents with access to Confidential Customer Media): <strong>{appStore.numberAccessIndividuals}</strong></p>
                <p>Total Individuals: <strong>{appStore.individuals.length}</strong></p>

                <p><button className="btn" onClick={()=>step3SubSection("individual-list")}>Edit Individuals List</button></p>

                <p>Service Vehicle Count: <strong>{appStore.vehicles.length}</strong></p>

                <p><button className="btn" onClick={()=>step3SubSection("vehicle-list")}>Edit Service Vehicle List</button></p>

                <label>Office Hours<br/>
                <input
                    type="text"
                    value={appStore.officeHours}
                    onChange={(event) => officeHoursUpdate(event.target.value)} />
                </label>

                <label>Hours of Operation (if applicable)<br/>
                <input
                    type="text"
                    value={appStore.operationHours}
                    onChange={(event) => operationHoursUpdate(event.target.value)} />
                </label>

                <p>Time first Service Vehicle Dispatch (if applicable)</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => firstDispatchUpdate("Consistent Daily")}>
                        <span className={appStore.firstDispatch === "Consistent Daily" ? "checked" : ""}></span>
                    Consistent Daily
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => firstDispatchUpdate("Varies Daily")}>
                        <span className={appStore.firstDispatch === "Varies Daily" ? "checked" : ""}></span>
                    Varies Daily
                    </label>
                </p>

                {appStore.firstDispatch === "Consistent Daily" &&
                    <div className='firstDispatchTimeDays'>
                        <label>Time<br/>
                        <input
                            type="time"
                            className="firstDispatchTime"
                            value={appStore.firstDispatchTime}
                            onChange={(event) => firstDispatchTimeUpdate(event.target.value)} />
                        </label>
                    </div>
                }
                {appStore.firstDispatch === "Varies Daily" &&
                    <div className='firstDispatchTimeDays'>
                        {Object.keys(appStore.firstDispatchTimeWk).map(key=><DispatchTimeWkField day={key} value={appStore.firstDispatchTimeWk[key]}/>)}
                    </div>
                }

                <p>Are Service Vehicles stored at a location other than the address previously mentioned? (if applicable)</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => storedAtOtherAddressUpdate(2)}>
                        <span className={appStore.storedAtOtherAddress === 2 ? "checked" : ""}></span>
                    No
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => storedAtOtherAddressUpdate(1)}>
                        <span className={appStore.storedAtOtherAddress === 1 ? "checked" : ""}></span>
                    Yes
                    </label>
                </p>
                {appStore.storedAtOtherAddress === 1 &&
                    <label>List Address:<br/>
                    <input
                        type="text"
                        value={appStore.storedAtOtherAddressInput}
                        onChange={(event) => storedAtOtherAddressInputUpdate(event.target.value)} />
                    </label>
                }

                <p>Please indicate Platform(s) by which media is processed at this location (Select all that apply):</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => programsUpdate('facilityBased')}>
                        <span className={appStore.programs.facilityBased ? "checked" : ""}></span>
                    Facility-based Destruction
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => programsUpdate('mobileOnsite')}>
                        <span className={appStore.programs.mobileOnsite ? "checked" : ""}></span>
                    Mobile / Onsite Destruction
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => programsUpdate('transferProc')}>
                        <span className={appStore.programs.transferProc ? "checked" : ""}></span>
                    Transfer Processing Station
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => programsUpdate('collectionOnly')}>
                        <span className={appStore.programs.collectionOnly ? "checked" : ""}></span>
                    Collection-only Facility
                    </label>
                </p>
                {(appStore.programs.transferProc || appStore.programs.collectionOnly) &&
                    <label>List Address of Affiliated Destruction Facility:<br/>
                    <input
                        type="text"
                        value={appStore.affiliatedFacilityAddress}
                        onChange={(event) => affiliatedFacilityAddressUpdate(event.target.value)} />
                    </label>
                }

                <p>Please indicate the Physical Media Destruction Endorsements that apply to this location (must include all provided):</p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('paper')}>
                        <span className={appStore.endorsements.paper === true ? "checked" : ""}></span>
                    Paper
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('microMedia')}>
                        <span className={appStore.endorsements.microMedia === true ? "checked" : ""}></span>
                    Micro Media
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('hardDrives')}>
                        <span className={appStore.endorsements.hardDrives === true ? "checked" : ""}></span>
                    Hard Drives
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('nonPaper')}>
                        <span className={appStore.endorsements.nonPaper === true ? "checked" : ""}></span>
                    Non-Paper Media (Optical Media)
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('solidState')}>
                        <span className={appStore.endorsements.solidState === true ? "checked" : ""}></span>
                    Solid-State Device
                    </label>
                </p>
                <p>
                    <label className="fancyCheckbox indentedCheckbox" onClick={() => endorsementsUpdate('product')}>
                        <span className={appStore.endorsements.product === true ? "checked" : ""}></span>
                    Product Destruction
                    </label>
                </p>

                <div className="nextPrevSection">
                    <div>
                        <Prev onClick={prevPage} buttonText="Back"/>
                    </div>
                    <div>
                        <Next onClick={nextPage} buttonText="Next"/>
                    </div>
                </div>
            </div>
        )
    }
})